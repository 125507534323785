import { React } from '../../dependencies';

const ArrowLeft = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g>
            <path d="M10,20 L11.435,18.606 L3.829,11 L20,11 L20,9 L3.829,9 L11.414,1.414 L10,0 C6.339,3.661 3.504,6.496 0,10 C3.966,13.966 1.167,11.166 10,20" />
        </g>
    </svg>
);

export default ArrowLeft;
