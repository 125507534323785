import { React, UI, useResetScrollOnMount } from '../../dependencies';

import Filter from '../../containers/Filter';
import List from '../List';

const { Container } = UI;

const Products = () => {
    useResetScrollOnMount();
    return (
        <Container>
            <Filter />
            <List />
        </Container>
    );
};

export default Products;
