const config = {
    firebase: {
        apiKey: 'AIzaSyDIMnG1ayPHMYLcNOmYWeIxKZg4qE4huSI',
        authDomain: 'sn.ack.ee',
        databaseURL: 'https://snackee.firebaseio.com',
        projectId: 'snackee',
        storageBucket: 'snackee.appspot.com',
        messagingSenderId: '1097135157524',
        appId: '1:1097135157524:web:6e8e3bb2ea0e7eae93d201',
        measurementId: 'G-CQHH608ZC2',
    },
    // TODO: fix ugprading SW
    serviceWorker: false,
};

export default config;
