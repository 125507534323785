import { React, PropTypes, FormattedMessage } from '../../dependencies';

import Label, { LabelSizes } from '../Label';
import Text, { TextSizes } from '../Text';
import EmptyValue from '../EmptyValue';

import * as ExtendStyles from './TextWithLabel.extendStyles';

const TextWithLabel = ({ styles, labelId, noData, children }) => (
    <div className={styles.textWithLabel}>
        <Label size={LabelSizes.LARGE} extend={ExtendStyles.label}>
            <FormattedMessage id={labelId} />
        </Label>
        <Text size={TextSizes.NORMAL}>
            <EmptyValue isEmpty={noData}>{children}</EmptyValue>
        </Text>
    </div>
);

TextWithLabel.propTypes = {
    styles: PropTypes.shape().isRequired,
    labelId: PropTypes.string.isRequired,
    children: PropTypes.node,
    noData: PropTypes.bool,
};

TextWithLabel.defaultProps = {
    noData: false,
    children: null,
};

export default TextWithLabel;
