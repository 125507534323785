import { strictObjectAccess } from '../../dependencies';

const TitleSizes = strictObjectAccess({
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
});

export function htmlTagFromTitleSize(textSize) {
    return TitleSizes[textSize.toUpperCase()];
}

export default TitleSizes;
