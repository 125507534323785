import { React } from '../../dependencies';

const DocumentHeart = props => (
    <svg viewBox="0 0 17 20" {...props}>
        <g>
            <polygon points="0.0081 -0.0002 0.0081 12.0008 2.0081 12.0008 2.0081 1.9998 8.0071 1.9998 8.0071 7.9998 14.0071 7.9998 14.0071 16.9998 9.0081 16.9998 9.0081 18.9998 16.0071 18.9998 16.0071 6.0798 10.2031 -0.0002" />
            <path d="M6.4745,13.0674 C5.5455,12.8264 4.7165,13.2574 4.1615,13.9964 C4.0845,14.0994 3.9295,14.1014 3.8525,13.9994 C3.2545,13.2104 2.3385,12.7714 1.3635,13.1224 C-0.1825,13.6774 -0.7225,16.1424 1.4055,18.2054 C3.6575,20.3874 4.0175,20.6834 6.5365,18.2754 C8.8095,16.1014 8.1835,13.5104 6.4745,13.0674" />
        </g>
    </svg>
);

export default DocumentHeart;
