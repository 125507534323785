export const container = () => ({
    position: 'relative',
    width: '100%',
    display: 'grid',
    paddingLeft: '0.9375rem',
    paddingRight: '0.9375rem',
    gridRowGap: '1rem',
    marginBottom: '2rem',

    desktop: {
        width: '81.94444%',
        display: 'inline-grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(7.3%, 1fr))',
        gridColumnGap: '1.5rem',
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
    },
});
