import { connect, sentryEventIdSelector, showReportDialog } from '../dependencies';

import ErrorWithAction from '../components/ErrorWithAction';

const mapStateToProps = state => {
    const eventId = sentryEventIdSelector(state);

    return {
        onClick() {
            showReportDialog({ eventId });
        },
        error: {
            id: 'error.internal',
        },
        buttonTextId: 'error.button.reportFeeback',
    };
};

export default connect(mapStateToProps)(ErrorWithAction);
