import { sagaEffects, eventChannel, Log, MessagesActions } from '../../dependencies';

import { installed, updated } from '../actions';

const { take, put } = sagaEffects;

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const documentLoaded = new Promise(resolve => {
    window.addEventListener('load', resolve);
});

const installingWorkerStateEmitter = registration =>
    eventChannel(emitter => {
        registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
                return;
            }
            installingWorker.onstatechange = () => {
                emitter(installingWorker.state);
            };
        };
        return () => {};
    });

function* registerValidSW(swUrl) {
    const { serviceWorker } = navigator;

    try {
        const registration = yield serviceWorker.register(swUrl);

        while (true) {
            const installingState = yield take(installingWorkerStateEmitter(registration));
            if (installingState === 'installed') {
                if (serviceWorker.controller) {
                    // At this point, the updated precached content has been fetched,
                    // but the previous service worker will still serve the older
                    // content until all client tabs are closed.
                    yield put(updated());
                } else {
                    // At this point, everything has been precached.
                    // It's the perfect time to display a
                    // "Content is cached for offline use." message.
                    yield put(installed());
                }
            }
        }
    } catch (e) {
        Log.error(e);
    }
}

function* checkValidServiceWorker(swUrl) {
    const { serviceWorker } = navigator;
    // Check if the service worker can be found. If it can't, reload the page.
    try {
        const response = yield fetch(swUrl);
        const contentType = response.headers.get('content-type');
        if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
            // No service worker found. Probably a different app. Reload the page.
            const registration = yield serviceWorker.ready;
            yield registration.unregister();
            window.location.reload();
        } else {
            // Service worker found. Proceed as normal.
            yield registerValidSW(swUrl);
        }
    } catch (e) {
        yield put(
            MessagesActions.displayInfoMessage({
                message: {
                    id: 'info.network.offline',
                },
            }),
        );
    }
}

export default function* register() {
    const { serviceWorker } = navigator;

    if (serviceWorker) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        yield documentLoaded;

        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        if (isLocalhost) {
            // This is running on localhost. Let's check if a service worker still exists or not.
            yield checkValidServiceWorker(swUrl);

            // Add some additional logging to localhost, pointing developers to the
            // service worker/PWA documentation.

            yield serviceWorker.ready;
            Log.log(
                'This web app is being served cache-first by a service ' +
                    'worker. To learn more, visit http://bit.ly/CRA-PWA',
            );
        } else {
            // Is not localhost. Just register service worker
            yield registerValidSW(swUrl);
        }
    }
}
