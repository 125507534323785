import { React } from '../../dependencies';

const ChevronLeft = props => (
    <svg viewBox="0 0 11 20" {...props}>
        <g>
            <path d="M9.6104,20 L11.0004,18.595 L2.7384,9.987 L3.6204,9.068 L3.6154,9.074 L10.9554,1.427 L9.5864,0 C7.5574,2.113 1.8934,8.014 0.0004,9.987 C1.4064,11.453 0.0354,10.023 9.6104,20" />
        </g>
    </svg>
);

export default ChevronLeft;
