import { union } from '../../../dependencies';
import { types } from '../../actions';

const initialState = {
    byId: {},
    ids: [],
};

export default function transactionsReducer(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_TRANSACTION_SUCCESS: {
            const transaction = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [transaction.id]: transaction,
                },
                ids: union([transaction.id], state.ids),
            };
        }

        case types.FETCH_TRANSACTIONS_SUCCESS: {
            const { byId, ids } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...byId,
                },
                ids: union(state.ids, ids),
            };
        }

        default:
            return state;
    }
}
