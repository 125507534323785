import { sagaEffects } from '../../dependencies';

import messagesVisibility from './messagesVisibility';
import createMessage from './createMessage';

const { all } = sagaEffects;

export default function*() {
    yield all([messagesVisibility(), createMessage()]);
}
