export const container = () => ({
    marginBottom: '0.75rem',
});

export const imageWrapper = () => ({
    height: '4rem',
    width: '4rem',

    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const image = () => ({
    height: 'auto',
    maxWidth: '64px',
    maxHeight: '64px',
    position: 'relative',
});

export const row = () => ({
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '4rem 1fr auto',
    gridColumnGap: '0.5rem',
});

export const imageFallback = ({ theme: { colors } }) => ({
    fontSize: '2rem',

    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    backgroundColor: colors.veryLightPink,
    borderRadius: '6px',
});

export const button = () => ({
    display: 'flex',
    alignItems: 'center',
});
