import { React, PropTypes, connectFela, FormattedMessage, UI } from '../../dependencies';

import * as Styles from './PageNotFound.styles';

const { Container } = UI;

function PageNotFound({ styles }) {
    return (
        <Container>
            <div className={styles.wrapper}>
                <h3 className={styles.error}>404</h3>
                <h2 className={styles.title}>
                    <FormattedMessage id="error.page.404.title" />
                </h2>
                <p className={styles.subtitle}>
                    <FormattedMessage id="error.page.404.subtitle" />
                </p>
            </div>
        </Container>
    );
}

PageNotFound.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default connectFela(Styles)(PageNotFound);
