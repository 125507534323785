import { React, Switch, Route, Config, Login, Redirect } from '../../dependencies';

const routes = [
    {
        render: () => <Login />,
        path: Config.routes.home,
    },
    {
        render: () => <Redirect to={Config.routes.home} />,
    },
];

export default function PublicRoutes() {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route key={index} exact {...route} />
            ))}
        </Switch>
    );
}
