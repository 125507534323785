import { React, PropTypes, childrenPropType } from '../../dependencies';

import Label, { LabelSizes } from '../Label';

const EmptyState = ({ styles, isEmpty, children, content, applyStyles }) => {
    if (!isEmpty) {
        return <>{children}</>;
    }

    return applyStyles ? (
        <div className={styles.content}>
            <Label size={LabelSizes.LARGE}>{content}</Label>
        </div>
    ) : (
        <>{content}</>
    );
};

EmptyState.propTypes = {
    styles: PropTypes.shape().isRequired,
    isEmpty: PropTypes.bool.isRequired,
    children: childrenPropType.isRequired,
    content: PropTypes.node.isRequired,
    applyStyles: PropTypes.bool,
};

EmptyState.defaultProps = {
    applyStyles: false,
};

export default EmptyState;
