import { React } from '../../dependencies';

const Done = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g>
            <path d="M10,18 C5.589,18 2,14.411 2,10 C2,5.589 5.589,2 10,2 C14.411,2 18,5.589 18,10 C18,14.411 14.411,18 10,18 M10,0 C4.478,0 0,4.477 0,10 C0,15.522 4.478,20 10,20 C15.522,20 20,15.522 20,10 C20,4.477 15.522,0 10,0" />
            <polygon points="9 11.5859 6.707 9.2929 5.293 10.7069 9 14.4139 14.707 8.7069 13.293 7.2929" />
        </g>
    </svg>
);

export default Done;
