import { React, PropTypes, childrenPropType } from '../../dependencies';

import Sizes, { htmlTagFromTitleSize } from './Title.sizes';

const Title = ({ children, size, styles }) => {
    return React.createElement(htmlTagFromTitleSize(size), { className: styles.title }, children);
};

Title.propTypes = {
    styles: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }).isRequired,
    children: childrenPropType.isRequired,
    size: PropTypes.oneOf(Object.values(Sizes)),
    link: PropTypes.bool,
};

Title.defaultProps = {
    link: false,
    size: Sizes.H1,
};

export default Title;
