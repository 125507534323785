import { React, PropTypes, UI, FormattedMessage } from '../../../dependencies';

const { Title, TitleSizes, Currency } = UI;

const Item = ({ styles, rules, item: { description, amount } }) => (
    <div className={styles.container}>
        <Title size={TitleSizes.H3} extend={rules.extendTitle}>
            {description}
        </Title>
        <div className={amount < 0 ? styles.negativeValue : styles.positiveValue}>
            <Currency value={amount} placeholder={<FormattedMessage id="product.forFree" />} />
        </div>
    </div>
);

Item.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        productId: PropTypes.string.isRequired,
    }).isRequired,
};

export default React.memo(Item);
