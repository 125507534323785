import { sagaEffects } from '../../dependencies';

import { saga as auth } from '../../modules/auth';
import { saga as products } from '../../modules/products';
import { saga as transactions } from '../../modules/transactions';
import { saga as users } from '../../modules/users';

const { all } = sagaEffects;

export default function*() {
    yield all([auth(), products(), transactions(), users()]);
}
