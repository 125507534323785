import {
    React,
    PropTypes,
    useSelector,
    Transactions,
    useDispatch,
    UI,
    FormattedMessage,
    Modals,
    Config,
} from '../../../../dependencies';

import FormInput from '../FormInput';

import { cardExtend } from './NewTransactionModal.extendStyles';

const { Button, ButtonSizes, ButtonTypes, Title, TitleSizes, Space } = UI;
const { Modal, useModal } = Modals;

const NewTransanctionModal = ({ styles }) => {
    const modal = useModal(Config.modals.addTransaction);
    const dispatch = useDispatch();

    const productId = Config.customProductId;
    const api = useSelector(state => Transactions.Selectors.createTransactionApiSelector(state, productId));

    const [name, setName] = React.useState(undefined);
    const [price, setPrice] = React.useState(undefined);

    React.useEffect(() => {
        setName(undefined);
        setPrice(undefined);
    }, [modal.isVisible]);

    const submit = () =>
        dispatch(
            Transactions.Actions.createTransactionRequest(productId, {
                name,
                price,
            }),
        );
    const isInvalid = !name || !price || price <= 0;

    return (
        <Modal id={Config.modals.addTransaction} cardExtend={cardExtend}>
            <Space bottom="0.5rem" />

            <Title size={TitleSizes.H2}>
                <FormattedMessage id="transactions.modal.title" />
            </Title>

            <form
                onSubmit={e => {
                    e.preventDefault();

                    if (!isInvalid) {
                        submit();
                    }
                }}
            >
                <div className={styles.body}>
                    <FormInput
                        {...{
                            name: 'name',
                            type: 'text',
                            value: name,
                            onChange: setName,
                            label: {
                                id: 'transactions.modal.name.label',
                            },
                            placeholder: {
                                id: 'transactions.modal.name.placeholder',
                            },
                            autoFocus: true,
                        }}
                    />

                    <Space bottom="1rem" />

                    <FormInput
                        {...{
                            name: 'price',
                            type: 'number',
                            value: price,
                            onChange: setPrice,
                            label: {
                                id: 'transactions.modal.price.label',
                            },
                            placeholder: {
                                id: 'transactions.modal.price.placeholder',
                            },
                        }}
                    />
                </div>

                <div className={styles.buttons}>
                    <Button
                        type={ButtonTypes.DEFAULT}
                        size={ButtonSizes.SMALL}
                        onClick={modal.closeModal}
                        disabled={api.inProgress}
                    >
                        <FormattedMessage id="transactions.modal.cancel" />
                    </Button>
                    <Button
                        type={ButtonTypes.PRIMARY}
                        size={ButtonSizes.SMALL}
                        disabled={isInvalid}
                        loading={api.inProgress}
                        htmlType="submit"
                    >
                        <FormattedMessage id="transactions.modal.submit" />
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

NewTransanctionModal.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default React.memo(NewTransanctionModal);
