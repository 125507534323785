export const header = ({ theme: { colors } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '4rem',
    borderBottom: `0.0625rem solid ${colors.paleGrey}`,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginLeft: '-1rem',
    marginRight: '-1rem',
    marginBottom: '0.75rem',
    overflow: 'hidden',
    position: 'relative',

    '> div': {
        display: 'flex',
        alignItems: 'center',
    },
});

export const extendTitle = {
    title: () => ({
        marginBottom: '0',
    }),
};
