import { sagaEffects } from '../../dependencies';

import { types, addMessageRequest } from '../actions';

const { takeEvery, put } = sagaEffects;

let id = 0;

function* createMessage(action) {
    const nextId = String(id++);

    const { payload, meta } = action;

    yield put(addMessageRequest(nextId, payload, meta));
}

const { DISPLAY_SUCCESS_MESSAGE, DISPLAY_ERROR_MESSAGE, DISPLAY_INFO_MESSAGE } = types;

export default function*() {
    yield takeEvery([DISPLAY_SUCCESS_MESSAGE, DISPLAY_ERROR_MESSAGE, DISPLAY_INFO_MESSAGE], createMessage);
}
