import { React } from '../../dependencies';

const Profile = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g>
            <path d="M10,12 C13.785,12 16.958,14.214 17.784,18 L2.216,18 C3.042,14.214 6.215,12 10,12 M6,6 C6,3.795 7.794,2 10,2 C12.206,2 14,3.795 14,6 C14,8.205 12.206,10 10,10 C7.794,10 6,8.205 6,6 M13.758,10.674 C15.124,9.574 16,7.891 16,6 C16,2.687 13.313,0 10,0 C6.687,0 4,2.687 4,6 C4,7.891 4.876,9.574 6.242,10.674 C2.583,12.048 0,15.445 0,20 L20,20 C20,15.445 17.417,12.048 13.758,10.674" />
        </g>
    </svg>
);

export default Profile;
