import { React, useSelector, useDispatch, useCurrentRef } from '../dependencies';

import { fetchProductsRequest, fetchProductsCancel } from '../services/actions';
import { getProductsApiSelector } from '../services/selectors';

export function useFetchProducts(params) {
    const { inProgress, success, error } = useSelector(state => getProductsApiSelector(state));
    const dispatch = useDispatch();
    const requestProducts = () => {
        if (!inProgress && !success) {
            dispatch(fetchProductsRequest(params));
        }
    };

    const inProgressRef = useCurrentRef(inProgress);

    React.useEffect(() => {
        requestProducts();

        return () => {
            if (inProgressRef.current) {
                dispatch(fetchProductsCancel());
            }
        };
    }, []);

    return {
        inProgress,
        success,
        error: {
            message: error,
            retry: requestProducts,
        },
    };
}
