import { TransactionsActions } from '../../../dependencies';
import { types } from '../../actions';

export default function usersReducer(state = 0, action) {
    switch (action.type) {
        case types.FETCH_USER_SUCCESS:
            return action.payload.balance;

        case TransactionsActions.types.CREATE_TRANSACTION_SUCCESS:
            return state + action.payload.amount;

        default:
            return state;
    }
}
