import { sagaEffects } from '../../dependencies';
import { MAX_COUNT, TRANSITION_DURATION } from '../../config';

import { types, removeMessage, addMessageResolve, hideMessage } from '../actions';
import { selectMessages } from '../selectors';

const { takeEvery, put, all, fork, cancel, select, delay } = sagaEffects;

function* closeMessage(id) {
    yield put(hideMessage(id));
    yield delay(TRANSITION_DURATION);
    yield put(removeMessage(id));
}

function* newMessageHandler(action) {
    const messages = yield select(selectMessages);

    if (messages.ids.length + 1 > MAX_COUNT) {
        yield fork(closeMessage, messages.ids[0]);
    }

    yield put(addMessageResolve(action.payload, action.meta));
}

const timeouts = new Map();

function* addMessageHandler(action) {
    const { id, duration } = action.meta;

    if (timeouts.has(id)) {
        yield put(removeMessage(id));
    }

    const task = yield fork(function*() {
        yield delay(duration);
        yield closeMessage(id);
    });

    timeouts.set(id, task);
}

function* removeMessageHandler(action) {
    const { id } = action.meta;

    const task = timeouts.get(id);

    yield cancel(task);

    timeouts.delete(id);
}

export default function* messagesVisibility() {
    yield all([
        takeEvery(types.ADD_MESSAGE_REQUEST, newMessageHandler),
        takeEvery(types.REMOVE_MESSAGE, removeMessageHandler),
        takeEvery(types.ADD_MESSAGE_RESOLVE, addMessageHandler),
    ]);
}
