import { sagaEffects, Log } from '../../dependencies';

import { types } from '../actions';

const { takeEvery } = sagaEffects;

export default function*() {
    yield takeEvery(types.REFRESH_PAGE, function*() {
        try {
            const sw = yield navigator.serviceWorker.getRegistration();

            yield sw.update();

            window.location.reload();
        } catch (e) {
            Log.error(e);
        }
    });
}
