import { React } from '../../dependencies';

const List = props => (
    <svg viewBox="0 0 20 18" {...props}>
        <g>
            <circle cx="2" cy="2" r="2" />
            <circle cx="2" cy="9" r="2" />
            <circle cx="2" cy="16" r="2" />
            <rect x="6" y="1" width="14" height="2" />
            <rect x="6" y="8" width="14" height="2" />
            <rect x="6" y="15" width="14" height="2" />
        </g>
    </svg>
);

export default List;
