import { sagaEffects } from '../../dependencies';

import fetchTransactions from './fetchTransactions';
import createTransaction from './createTransaction';

const { all } = sagaEffects;

export default function*() {
    yield all([createTransaction(), fetchTransactions()]);
}
