import React from 'react';

const SadFace = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <path d="M9.9998,18 C5.5888,18 1.9998,14.411 1.9998,10 C1.9998,5.589 5.5888,2 9.9998,2 C14.4108,2 17.9998,5.589 17.9998,10 C17.9998,14.411 14.4108,18 9.9998,18 M9.9998,0 C4.4778,0 -0.0002,4.478 -0.0002,10 C-0.0002,15.522 4.4778,20 9.9998,20 C15.5228,20 19.9998,15.522 19.9998,10 C19.9998,4.478 15.5228,0 9.9998,0" />
        <path d="M6.5789,8.5 C7.4079,8.5 8.0789,7.828 8.0789,7 C8.0789,6.172 7.4079,5.5 6.5789,5.5 C5.7509,5.5 5.0789,6.172 5.0789,7 C5.0789,7.828 5.7509,8.5 6.5789,8.5" />
        <path d="M13.4187,8.5 C14.2477,8.5 14.9187,7.828 14.9187,7 C14.9187,6.172 14.2477,5.5 13.4187,5.5 C12.5897,5.5 11.9187,6.172 11.9187,7 C11.9187,7.828 12.5897,8.5 13.4187,8.5" />
        <path d="M9.9988,10.5244 C7.5238,10.5244 5.2618,12.0754 4.3698,14.3834 L6.2348,15.1044 C6.8318,13.5614 8.3438,12.5244 9.9988,12.5244 C11.6538,12.5244 13.1658,13.5614 13.7628,15.1044 L15.6278,14.3834 C14.7358,12.0754 12.4738,10.5244 9.9988,10.5244" />
    </svg>
);

export default SadFace;
