import { React, PropTypes, CustomPropTypes, NavLink, FormattedMessage, UI } from '../../../dependencies';

const { Label, LabelSizes } = UI;

const NavItem = ({ styles, to, label, icon }) => (
    <NavLink to={to} className={styles.navItem} activeClassName={styles.navItemActive} exact>
        <span className={styles.icon}>{icon}</span>
        <Label size={LabelSizes.NORMAL}>
            <FormattedMessage {...label} />
        </Label>
    </NavLink>
);

NavItem.propTypes = {
    styles: PropTypes.shape().isRequired,
    to: PropTypes.string.isRequired,
    label: CustomPropTypes.message.isRequired,
    icon: PropTypes.element.isRequired,
};

export default NavItem;
