export const EntityKeys = {
    PRODUCTS: 'products',
    PRODUCT: 'product',

    TRANSACTION: 'transaction',
    TRANSACTIONS: 'transactions',

    USER: 'user',
    USERS: 'users',
};

export const ApiTypes = {
    GET: 'get',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
};
