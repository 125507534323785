import { React } from '../../dependencies';

const CrazyFace = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g>
            <path d="M9.9999,18.0001 C5.5889,18.0001 1.9999,14.4111 1.9999,10.0001 C1.9999,5.5891 5.5889,2.0001 9.9999,2.0001 C14.4109,2.0001 17.9999,5.5891 17.9999,10.0001 C17.9999,14.4111 14.4109,18.0001 9.9999,18.0001 M9.9999,0.0001 C4.4769,0.0001 -0.0001,4.4771 -0.0001,10.0001 C-0.0001,15.5221 4.4769,20.0001 9.9999,20.0001 C15.5229,20.0001 19.9999,15.5221 19.9999,10.0001 C19.9999,4.4771 15.5229,0.0001 9.9999,0.0001" />
            <path d="M7.579,9.5001 C8.96,9.5001 10.079,8.3811 10.079,7.0001 C10.079,5.6191 8.96,4.5001 7.579,4.5001 C6.199,4.5001 5.079,5.6191 5.079,7.0001 C5.079,8.3811 6.199,9.5001 7.579,9.5001" />
            <path d="M13.4188,8.5001 C14.2478,8.5001 14.9188,7.8281 14.9188,7.0001 C14.9188,6.1711 14.2478,5.5001 13.4188,5.5001 C12.5898,5.5001 11.9188,6.1711 11.9188,7.0001 C11.9188,7.8281 12.5898,8.5001 13.4188,8.5001" />
            <polygon points="4.745 14.601 15.745 14.601 15.745 12.601 4.745 12.601" />
        </g>
    </svg>
);

export default CrazyFace;
