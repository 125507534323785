import { React, PropTypes, FormattedNumber, Config } from '../../dependencies';

import EmptyValue from '../EmptyValue';

function Currency({ value, currency, minimumFractionDigits, maximumFractionDigits, placeholder, isEmpty }) {
    return (
        <EmptyValue isEmpty={!value && !isEmpty} placeholder={placeholder}>
            <FormattedNumber
                {...{
                    value,
                    currency,
                    minimumFractionDigits,
                    maximumFractionDigits,
                    style: 'currency',
                }}
            />
        </EmptyValue>
    );
}

Currency.propTypes = {
    value: PropTypes.number,
    currency: PropTypes.string,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number,
    placeholder: PropTypes.node,
    isEmpty: PropTypes.bool,
};

Currency.defaultProps = {
    ...Config.currencyFormat,
    isEmpty: false,
};

export default Currency;
