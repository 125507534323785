import { React } from '../../dependencies';

const Invoice = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g>
            <path d="M7,10 L13,10 L13,8 L7,8 L7,10 Z M7,6 L13,6 L13,4 L7,4 L7,6 Z M16,4 L16,2 C17.103,2 18,2.897 18,4 L16,4 Z M14,16 C14,17.103 13.103,18 12,18 L12,14 L6,14 L6,4 C6,2.897 6.897,2 8,2 L14,2 L14,16 Z M10,18 L4,18 C2.897,18 2,17.103 2,16 L10,16 L10,18 Z M16,0 L8,0 C5.791,0 4,1.791 4,4 L4,14 L0,14 L0,16 C0,18.209 1.791,20 4,20 L12,20 C14.209,20 16,18.209 16,16 L16,6 L20,6 L20,4 C20,1.791 18.209,0 16,0 L16,0 Z" />
        </g>
    </svg>
);

export default Invoice;
