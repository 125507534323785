import { createAsyncType } from '../../dependencies';

import { MODULE_NAME } from '../../config';

const asyncType = createAsyncType({
    modulePrefix: MODULE_NAME,
});

export default {
    ...asyncType({
        types: ['OPEN_MODAL', 'CLOSE_MODAL'],
    }),
};
