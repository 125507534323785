export const list = ({ theme: { zIndex } }) => ({
    position: 'fixed',
    zIndex: zIndex.messages,
    display: 'grid',
    gridRowGap: '1rem',

    bottom: '4.75rem',
    left: '1rem',
    width: 'calc(100% - 2rem)',
});
