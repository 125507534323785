import { Config } from '../../dependencies';

import register from './register';
import unregister from './unregister';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
export default function*() {
    if (Config.serviceWorker) {
        yield register();
    } else {
        yield unregister();
    }
}
