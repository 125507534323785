import { React, PropTypes } from '../../dependencies';

import { Size } from './Skeleton.consts';

const Skeleton = ({ styles }) => <div className={styles.skeleton} />;

Skeleton.propTypes = {
    styles: PropTypes.shape().isRequired,

    // eslint-disable-next-line
    size: PropTypes.oneOf(Object.values(Size)).isRequired,
    // eslint-disable-next-line
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Skeleton;
