import { React, PropTypes, childrenPropType } from '../../dependencies';

import ErrorWithAction from '../../containers/ErrorWithAction';
import { errorPropType } from '../propTypes';

function ErrorCard({ message, retry, children }) {
    if (!message) {
        return <>{children}</>;
    }
    return <ErrorWithAction error={message} onClick={retry} />;
}

ErrorCard.propTypes = {
    message: errorPropType,
    retry: PropTypes.func.isRequired,
    children: childrenPropType.isRequired,
};

ErrorCard.defaultProps = {
    message: null,
};

export default ErrorCard;
