import { strictObjectAccess, createAsyncType } from '../../dependencies';

const asyncType = createAsyncType({
    modulePrefix: 'ui/message',
});

export default strictObjectAccess({
    ...asyncType({
        types: ['ADD_MESSAGE_REQUEST', 'ADD_MESSAGE_RESOLVE', 'REMOVE_MESSAGE', 'HIDE_MESSAGE', 'CLEAR_MESSAGES'],
    }),
    ...asyncType({
        types: ['DISPLAY_SUCCESS_MESSAGE', 'DISPLAY_ERROR_MESSAGE', 'DISPLAY_INFO_MESSAGE'],
    }),
});
