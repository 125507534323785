import { React } from '../../dependencies';

const Underline = props => (
    <svg viewBox="0 0 21 22" {...props}>
        <g transform="translate(0.000000, -1.000000)">
            <g transform="translate(1.000000, 0.989300)">
                <path d="M16.9854,8.4033 C16.9854,14.5843 14.6024,18.0103 8.9284,18.0103 C3.3684,18.0103 0.9854,14.5843 0.9854,8.4033 L0.9854,0.0103 L5.0424,0.0103 L5.0424,8.9373 C5.0424,12.7013 6.5734,14.4993 8.9864,14.4993 C11.3974,14.4993 12.9284,12.7013 12.9284,8.9373 L12.9284,0.0103 L16.9854,0.0103 L16.9854,8.4033 Z" />
            </g>
            <polygon points="0 22.9893 20.048 22.9893 20.048 21.0003 0 21.0003" />
        </g>
    </svg>
);

export default Underline;
