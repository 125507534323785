export const loader = ({ inline, theme: { zIndex } }) => {
    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: 'transparent',
    };

    if (inline) {
        return {
            ...style,
            position: 'relative',
            minHeight: '100px',
        };
    }

    return {
        ...style,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: inline ? 1 : zIndex.appLoader,
    };
};

export const text = () => ({
    marginTop: '0.5rem',
});

export const heart = ({ animationName }) => ({
    fontSize: '4rem',

    animation: `1000ms ${animationName} infinite`,
});
