import { strictObjectAccess, createAsyncType, EntityKeys } from '../../dependencies';

const createTypes = createAsyncType({
    modulePrefix: EntityKeys.PRODUCTS,
});

export default strictObjectAccess({
    ...createTypes({
        typePrefix: 'FETCH_PRODUCTS_',
    }),
});
