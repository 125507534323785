import { React, PropTypes, childrenPropType } from '../../dependencies';

import Header from './Header';

const Card = ({ styles, children, header, button }) => {
    return (
        <div className={styles.card}>
            {header && <Header button={button}>{header}</Header>}
            {children}
        </div>
    );
};

Card.propTypes = {
    styles: PropTypes.shape().isRequired,
    children: childrenPropType.isRequired,
    header: PropTypes.node,
    button: PropTypes.node,
};

Card.defaultProps = {
    header: null,
    button: null,
};

export default Card;
