import { combineReducers } from 'modules/entities/dependencies';
import { EntityKeys } from 'modules/entities/constants';

import { apiReducers as authApiReducers } from 'modules/entities/modules/auth';
import { apiReducers as productsApiReducers } from 'modules/entities/modules/products';
import { apiReducers as transactionsApiReducers } from 'modules/entities/modules/transactions';
import { apiReducers as usersApiReducers } from 'modules/entities/modules/users';

export default combineReducers({
    ...authApiReducers,

    [EntityKeys.PRODUCTS]: productsApiReducers[EntityKeys.PRODUCTS],

    [EntityKeys.TRANSACTIONS]: transactionsApiReducers[EntityKeys.TRANSACTIONS],
    [EntityKeys.TRANSACTION]: transactionsApiReducers[EntityKeys.TRANSACTION],

    [EntityKeys.USER]: usersApiReducers[EntityKeys.USER],
});
