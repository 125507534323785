import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

import Config from '../config';

const app = firebase.initializeApp(Config.firebase);

export const firebaseApp = {
    auth: app.auth(),
    firestore: app.firestore(),
    database: app.database(),
};

export default firebase;

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const AuthProviders = {
    google: googleProvider,
};
