export const container = () => ({});

export const navItem = () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
});

export const icon = () => ({
    fontSize: '1.5rem',
    display: 'block',
});

export const navItemActive = ({ theme: { colors } }) => ({
    '& > span': {
        color: colors.darkIndigo,
        fontWeight: 500,
    },
});
