import { React } from '../../dependencies';

const Upload = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g>
            <path d="M11.034,12.063 L11.034,3.698 L13.578,6.125 L14.94,4.671 L9.992,-2.22044605e-15 L5.094,4.671 L6.442,6.125 L9.034,3.698 L9.034,12.063 L11.034,12.063 Z M16,10 L16,12 L18,12 L18,18 L2,18 L2,12 L4,12 L4,10 L0,10 L0,20 L20,20 L20,10 L16,10 Z" />
        </g>
    </svg>
);

export default Upload;
