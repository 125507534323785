import { React, PropTypes } from '../../dependencies';

const Email = ({ value, styles }) => (
    <a href={`mailto:${value}`} target="_blank" rel="noreferrer noopener" className={styles.email}>
        {value}
    </a>
);

Email.propTypes = {
    value: PropTypes.string.isRequired,
    styles: PropTypes.shape().isRequired,
};

export default Email;
