export const container = ({ columns }) => {
    return {
        gridColumn: `auto / span 10`,
        overflow: 'hidden',

        desktop: {
            gridColumn: `auto / span ${columns}`,
        },
    };
};
