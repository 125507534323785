// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"colors.less"}]);
// Exports
exports.locals = {
	"white": "#fff",
	"lilac": "#f3a3ff",
	"lightLavendar": "#f7c3ff",
	"primaryBlue": "#0000ff",
	"cornflower": "#6699ff",
	"veryLightBlue": "#e0ebff",
	"teal": "#00a378",
	"aquamarine": "#00dda2",
	"darkIndigo": "#10092d",
	"blueyGrey": "#9393a2",
	"cloudyBlue": "#babac4",
	"paleGrey": "#ededf2",
	"veryLightPink": "#f8f6f6",
	"red": "#ff0000",
	"brownGrey": "#9b9b9b",
	"lightBlueGrey": "#c3c3cb",
	"marigold": "#ffc100",
	"darkSkyBlue": "#38b8fc",
	"borderGrey": "#979797",
	"paleAqua": "#bbffdb",
	"vermillion": "#ff2106",
	"pale": "#ffdbd6",
	"silver70": "rgba(174, 224, 198, 0.7)",
	"brownishPink40": "rgba(210, 126, 126, 0.4)",
	"carolinaBlue": "#80aaff",
	"ice": "#e0fff7",
	"paleGreyTwo": "#ebf6ff"
};
module.exports = exports;
