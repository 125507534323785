import { React } from '../../dependencies';

const Bold = props => (
    <svg viewBox="0 0 14 20" {...props}>
        <g>
            <path d="M4.1279,16.5713 L5.0809,16.5713 C6.2929,16.5713 7.5629,16.5713 8.4299,16.1423 C9.1499,15.7713 9.5829,15.1713 9.5829,14.1423 C9.5829,12.4853 8.4869,11.4573 6.4379,11.4573 L4.1279,11.4573 L4.1279,16.5713 Z M4.1279,8.1423 L5.2819,8.1423 C7.6489,8.1423 9.0359,7.3713 9.0359,5.8003 C9.0359,4.8853 8.6599,4.3133 8.1699,3.9423 C7.3899,3.4273 6.2359,3.4273 4.9369,3.4273 L4.1279,3.4273 L4.1279,8.1423 Z M5.3119,0.0003 C7.2459,0.0003 9.2959,0.3133 10.7969,1.2853 C12.3549,2.2853 13.1629,3.8563 13.1629,5.6573 C13.1629,7.3143 12.2979,8.7713 10.6229,9.5133 C12.6719,10.2003 13.9999,11.9433 13.9999,14.0563 C13.9999,16.4283 12.5859,18.2863 10.3919,19.2003 C8.8619,19.8283 6.8989,20.0003 5.0809,20.0003 L-0.0001,20.0003 L-0.0001,0.0003 L5.3119,0.0003 Z" />
        </g>
    </svg>
);

export default Bold;
