import { React, PropTypes, Config } from '../../dependencies';

import NavItem from './NavItem';

const NavItems = [
    {
        to: Config.routes.home,
        label: {
            id: 'navbar.item.snacks',
        },
        icon: <span role="img">🥐</span>,
    },
    {
        to: Config.routes.transactions,
        label: {
            id: 'navbar.item.transactions',
        },
        icon: <span role="img">💵</span>,
    },
];

const Navbar = ({ styles }) => (
    <div className={styles.navbar}>
        <div className={styles.container}>
            {NavItems.map(navItem => (
                <NavItem key={navItem.to} {...navItem} />
            ))}
        </div>
    </div>
);

Navbar.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default Navbar;
