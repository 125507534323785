import { React, PropTypes, childrenPropType } from '../dependencies';

import Modal from '../components/Modal';
import { useModal } from '../hooks';

function ModalContainer({ id, children, cardExtend }) {
    const { closeModal, isVisible } = useModal(id);

    return (
        isVisible && (
            <Modal closeModal={closeModal} cardExtend={cardExtend}>
                {children}
            </Modal>
        )
    );
}

ModalContainer.propTypes = {
    id: PropTypes.string.isRequired,
    children: childrenPropType.isRequired,
};

export default ModalContainer;
