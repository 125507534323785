export const balance = () => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '1.5rem 0 1rem',
});

export const extendTitle = () => ({
    title: () => ({
        margin: 0,
    }),
});
