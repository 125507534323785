import { basicApiReducer, containerReducer } from '../../../../dependencies';
import { types } from '../../../actions';

const actionTypes = {
    REQUEST: types.CREATE_TRANSACTION_REQUEST,
    SUCCESS: types.CREATE_TRANSACTION_SUCCESS,
    FAILURE: types.CREATE_TRANSACTION_FAILURE,
    CANCEL: types.CREATE_TRANSACTION_CANCEL,
    RESET: types.CREATE_TRANSACTION_RESET,
};

const apiReducer = basicApiReducer({
    actionTypes,
});

export default containerReducer({
    childReducer: apiReducer,
    actionTypes: Object.values(actionTypes),
    selectors: {
        itemId: action => action.meta.productId,
    },
});
