export const container = ({ theme: { colors } }) => ({
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingTop: '3.5rem',
    paddingBottom: '3.5rem',
    background: '#fff',
    borderRadius: '1.25rem',
    boxShadow: '0px 4px 12px 0px rgba(0,0,0,0.2)',

    '> svg': {
        width: '3.875rem',
        height: '3.875rem',
        marginBottom: '5px',
    },

    '> h1': {
        fontSize: '2rem',
    },

    '> span': {
        color: colors.blueyGrey,
        marginBottom: '6.625rem',
        maxWidth: '14.375rem',
    },

    '> button': {
        '> svg': {
            width: '20px',
            height: '20px',
            marginRight: '10px',
            fill: colors.white,
        },
    },
});
