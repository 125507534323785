import types from './types';

export const fetchProductsRequest = params => ({
    type: types.FETCH_PRODUCTS_REQUEST,
    payload: { params },
});

export const fetchProductsSuccess = payload => ({
    type: types.FETCH_PRODUCTS_SUCCESS,
    payload,
});

export const fetchProductsFailure = error => ({
    type: types.FETCH_PRODUCTS_FAILURE,
    error,
});

export const fetchProductsCancel = () => ({
    type: types.FETCH_PRODUCTS_CANCEL,
});
