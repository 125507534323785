import { React, PropTypes, FormattedMessage } from '../../dependencies';

import Button, { ButtonTypes } from '../Button';
import { CrazyFace, Logo } from '../Icons';
import Title, { TitleSizes } from '../Title';
import Text, { TextSizes } from '../Text';

const LoginForm = ({ styles, onClick }) => (
    <div className={styles.container}>
        <Logo />
        <Title size={TitleSizes.H1}>
            <FormattedMessage id="loginForm.heading" />
        </Title>
        <Text size={TextSizes.SMALL}>
            <FormattedMessage id="loginForm.perex" />
        </Text>
        <Button onClick={onClick} type={ButtonTypes.PRIMARY}>
            <CrazyFace />
            <FormattedMessage id="button.login" />
        </Button>
    </div>
);

LoginForm.propTypes = {
    onClick: PropTypes.func.isRequired,

    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
};

export default LoginForm;
