export const container = ({ theme: { colors }, isLast }) => ({
    padding: '0.75rem 0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(2, 1fr)',
    alignItems: 'center',
    borderBottom: isLast ? 'unset' : `1px solid ${colors.paleGrey}`,
});

export const extendTitle = {
    title: () => ({ marginBottom: 0 }),
};

export const positiveValue = ({ theme: { colors } }) => ({
    backgroundColor: colors.paleAqua,
    padding: '0 4px',
    borderRadius: '2px',
});

export const negativeValue = ({ theme: { colors } }) => ({
    backgroundColor: colors.pale,
    padding: '0 4px',
    borderRadius: '2px',
});
