import { React, PropTypes, FormattedMessage, UI, useDispatch, useSelector, Auth } from '../../dependencies';

const { Button, ButtonTypes, Icons } = UI;

const Logout = ({ rules }) => {
    const dispatch = useDispatch();
    const signOutApi = useSelector(Auth.Selectors.signOutApiSelector);

    return (
        <Button
            extend={rules.extendButton}
            onClick={() => dispatch(Auth.Actions.signOutRequest())}
            loading={signOutApi.inProgress}
            type={ButtonTypes.DEFAULT}
        >
            <Icons.Logout />
            <FormattedMessage id="button.logout" />
        </Button>
    );
};

Logout.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default Logout;
