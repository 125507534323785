import { React, useDispatch, useSelector } from '../../../dependencies';

import * as Actions from '../services/actions';
import { isModalVisibleSelector } from '../services/selectors';

function useKeyDown({ enabled, keyCode, callback }) {
    React.useEffect(() => {
        const keyDownHandler = event => {
            if (event.keyCode === keyCode) {
                callback();
            }
        };

        document.addEventListener('keydown', keyDownHandler, false);

        return () => {
            document.removeEventListener('keydown', keyDownHandler, false);
        };
    }, [enabled]);
}

export function useModal(id) {
    const isVisible = useSelector(state => isModalVisibleSelector(state, id));
    const dispatch = useDispatch();

    const openModal = () => {
        dispatch(Actions.openModal(id));
    };
    const closeModal = () => {
        dispatch(Actions.closeModal(id));
    };

    const ESC_KEY_CODE = 27;

    useKeyDown({
        enabled: isVisible,
        keyCode: ESC_KEY_CODE,
        callback: closeModal,
    });

    return {
        isVisible,
        closeModal,
        openModal,
    };
}
