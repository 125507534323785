import { compose, connect, debounce } from '../dependencies';

import Filter from '../components/Filter';

import { filterProducts } from '../services/actions';

export default compose(
    connect(null, dispatch => ({
        handleChange: debounce(value => dispatch(filterProducts(value)), 250),
        handleClear() {
            dispatch(filterProducts(''));
        },
    })),
)(Filter);
