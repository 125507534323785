import { React } from '../../dependencies';

const Plus = props => (
    <svg viewBox="0 0 14 14" {...props}>
        <g>
            <polygon points="6 1.26121336e-13 6 6 7.46069873e-14 6 7.46069873e-14 8 6 8 6 14 8 14 8 8 14 8 14 6 8 6 8 1.26121336e-13" />
        </g>
    </svg>
);

export default Plus;
