export default ({ theme }) => ({
    '& .ant-input': {
        height: '2.5rem',
        padding: 'unset',
        paddingLeft: '0.75rem',
        paddingRight: '0.5rem',

        fontFamily: theme.fontFamily,
        fontSize: '0.9rem',
        lineHeight: '1.25rem',

        display: 'flex',
        alignItems: 'center',

        color: theme.colors.black,
        backgroundColor: `#fff`,
        border: 'none',
        boxShadow: '0 2px 0 rgba(0, 0, 0, 0.015)',
        borderRadius: '6px',

        ':hover': {
            border: 'none',
            boxShadow: 'none',
        },
        ':focus': {
            border: 'none',
            boxShadow: 'none',
        },
    },
    '& .ant-input:not(:last-child)': {
        paddingRight: '3.5rem',
    },
});
