import { types } from '../actions';

export default function modalsReducer(state = {}, action) {
    switch (action.type) {
        case types.OPEN_MODAL:
            return {
                ...state,
                [action.meta.id]: true,
            };

        case types.CLOSE_MODAL:
            return {
                ...state,
                [action.meta.id]: false,
            };

        default:
            return state;
    }
}
