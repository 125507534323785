import { React, PropTypes, AntdButton, childrenPropType } from '../../dependencies';

import { ButtonSizes, ButtonTypes, AntdButtonSizes } from './Button.consts';

const Button = ({ theme, rules, styles, children, outlined, size, ...props }) => {
    return (
        <AntdButton className={styles.button} ghost={outlined} size={AntdButtonSizes[size]} {...props}>
            {children}
        </AntdButton>
    );
};

Button.propTypes = {
    styles: PropTypes.shape().isRequired,
    size: PropTypes.oneOf(Object.values(ButtonSizes)),
    type: PropTypes.oneOf(Object.values(ButtonTypes)),
    outlined: PropTypes.bool,
    children: childrenPropType.isRequired,
};

Button.defaultProps = {
    size: ButtonSizes.NORMAL,
    type: ButtonTypes.DEFAULT,
    outlined: false,
};

export default Button;
