import { sagaEffects } from '../../dependencies';

import handleServiceWorkerRegistration from './handleServiceWorkerRegistration';
import handleServiceWorkerState from './handleServiceWorkerState';
import refreshPage from './refreshPage';

const { all } = sagaEffects;

export default function*() {
    yield all([handleServiceWorkerRegistration(), handleServiceWorkerState(), refreshPage()]);
}
