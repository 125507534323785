import { sagaEffects, MessagesActions } from '../../dependencies';

import { types, refreshPage } from '../actions';

const { takeEvery, put } = sagaEffects;

export default function*() {
    yield takeEvery(types.UPDATED, function*() {
        yield put(
            MessagesActions.displayInfoMessage({
                message: {
                    id: 'info.sw.updateAvailable',
                },
                button: {
                    message: {
                        id: 'updateAvailable.button',
                    },
                    onClick: refreshPage(),
                },
                options: {
                    duration: 1000 * 60,
                },
            }),
        );
    });
}
