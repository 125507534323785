import { React, Switch, Route, Config, Navbar, Redirect, Products, Transactions } from '../../dependencies';

const routes = [
    {
        render: () => <Products />,
        path: Config.routes.home,
    },
    {
        render: () => <Transactions />,
        path: Config.routes.transactions,
    },
    {
        render: () => <Redirect to={Config.router.home} />,
    },
];

export default function AuthRoutes() {
    return (
        <>
            <Switch>
                {routes.map((route, index) => (
                    <Route key={index} exact {...route} />
                ))}
            </Switch>
            <div
                style={{
                    height: 'calc(4rem + env(safe-area-inset-bottom))',
                    overflow: 'hidden',
                    position: 'relative',
                }}
            />
            <Navbar />
        </>
    );
}
