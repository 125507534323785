import { React } from '../../dependencies';

const ErrorSelected = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle fill="#FF0000" cx="10" cy="10" r="10" />
            <polygon
                fill="#FFFFFF"
                points="12.293 6.293 10 8.586 7.707 6.293 6.293 7.707 8.586 10 6.293 12.293 7.707 13.707 10 11.414 12.293 13.707 13.707 12.293 11.414 10 13.707 7.707"
            />
        </g>
    </svg>
);

export default ErrorSelected;
