import { React } from '../../dependencies';

const ChevronRightSmall = props => (
    <svg viewBox="0 0 9 14" {...props}>
        <g>
            <polygon points="1.4625785 0 0 1.47670518 5.474843 7.0005218 0 12.5243384 1.4625785 14 8.4 7.0005218" />
        </g>
    </svg>
);

export default ChevronRightSmall;
