import { createAsyncType } from '../../dependencies';

const asyncType = createAsyncType({
    modulePrefix: 'auth',
});

export default {
    ...asyncType({
        typePrefix: 'SIGN_IN_',
    }),
    ...asyncType({
        typePrefix: 'SIGN_OUT_',
    }),
    ...asyncType({
        types: ['SET_AUTH_FLOW_TYPE'],
    }),
};
