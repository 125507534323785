import { strictObjectAccess } from '../dependencies';

export const ErrorUIMessages = strictObjectAccess({
    code: {
        e1000: {
            id: 'error.api.code.e1000',
        },
        e1001: {
            id: 'error.api.code.e1001',
        },
        e1002: {
            id: 'error.api.code.e1002',
        },
        e2000: {
            id: 'error.api.code.e2000',
        },
        e2001: {
            id: 'error.api.code.e2001',
        },
        e2002: {
            id: 'error.api.code.e2002',
        },
        e3000: {
            id: 'error.api.code.e3000',
        },
        e4000: {
            id: 'error.api.code.e4000',
        },
    },
    status: {},
    fallback: {
        id: 'error.api.general',
    },
});
