import { React, PropTypes, childrenPropType } from '../../dependencies';

const EmptyValue = ({ isEmpty, children, placeholder }) => (isEmpty ? placeholder : <>{children}</>);

EmptyValue.propTypes = {
    isEmpty: PropTypes.bool.isRequired,
    placeholder: PropTypes.node,
    children: childrenPropType.isRequired,
};

EmptyValue.defaultProps = {
    placeholder: '-',
};

export default EmptyValue;
