import { React } from '../../dependencies';

const Italic = props => (
    <svg viewBox="0 0 12 20" {...props}>
        <g>
            <polygon points="7.642 -6.39488462e-14 4.4408921e-15 20 4.289 20 11.931 -6.39488462e-14" />
        </g>
    </svg>
);

export default Italic;
