import types from './types';

export const fetchTransactionsRequest = params => ({
    type: types.FETCH_TRANSACTIONS_REQUEST,
    payload: { params },
});

export const fetchTransactionsSuccess = payload => ({
    type: types.FETCH_TRANSACTIONS_SUCCESS,
    payload,
});

export const fetchTransactionsFailure = error => ({
    type: types.FETCH_TRANSACTIONS_FAILURE,
    error,
});

export const fetchTransactionsCancel = () => ({
    type: types.FETCH_TRANSACTIONS_CANCEL,
});
