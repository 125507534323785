import { React } from '../../dependencies';

const Calender = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g>
            <path d="M17.971246,8 L1.99680511,8 L1.99680511,4.971 C1.99680511,4.435 2.43111022,4 2.96625399,4 L3.99361022,4 L3.99361022,6 L5.99041534,6 L5.99041534,4 L13.9776358,4 L13.9776358,6 L15.9744409,6 L15.9744409,4 L16.9728435,4 C17.5239617,4 17.971246,4.447 17.971246,5 L17.971246,8 Z M17.971246,17 C17.971246,17.55 17.5219649,18 16.9728435,18 L2.99520767,18 C2.44408946,18 1.99680511,17.553 1.99680511,17 L1.99680511,10 L17.971246,10 L17.971246,17 Z M0.062899361,17.761 C0.062899361,18.865 1.02036741,20 2.12360224,20 L18.0980431,20 C19.201278,20 20,18.979 20,17.761 C20,17.371 19.9680511,5.359 19.9680511,4.708 C19.9680511,2.626 19.6875,2 15.9744409,2 L15.9744409,0 L13.9776358,0 L13.9776358,2 L5.99041534,2 L5.99041534,0 L3.99361022,0 L3.99361022,2 L1.99680511,2 C0.8985623,2 0,2.9 0,4 L0.062899361,17.761 Z" />
        </g>
    </svg>
);

export default Calender;
