import { union } from '../../dependencies';
import { types } from '../actions';

const initialState = [];

export default function messagesReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_MESSAGE_RESOLVE:
            return union(state, [action.meta.id]);

        case types.REMOVE_MESSAGE:
            return state.filter(itemId => itemId !== action.meta.id);

        case types.CLEAR_MESSAGES:
            return initialState;

        default:
            return state;
    }
}
