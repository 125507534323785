import types from './types';

export const openModal = id => ({
    type: types.OPEN_MODAL,
    meta: { id },
});

export const closeModal = id => ({
    type: types.CLOSE_MODAL,
    meta: { id },
});
