export const container = ({ theme: { zIndex } }) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: zIndex.modal,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const overlay = () => ({
    background: 'rgba(16, 9, 46, 0.1)',
    position: 'absolute',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    top: 0,
    left: 0,
    zIndex: 1,
});

export const extendCard = () => ({
    maxWidth: '35.375rem',
    position: 'relative',
    zIndex: 2,
});
