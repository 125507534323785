import Sizes from './Label.sizes';

export const label = ({ size, theme: { colors } }) => {
    const base = {
        fontWeight: 500,
        color: colors.blueyGrey,
    };

    switch (size) {
        case Sizes.LARGE:
            return Object.assign(base, {
                fontSize: '0.81rem',
                lineHeight: 1.23,
            });

        case Sizes.NORMAL:
            return Object.assign(base, {
                fontSize: '0.75rem',
                lineHeight: 1.25,
                letterSpacing: '0.1px',
            });

        default:
            return base;
    }
};
