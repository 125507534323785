import React from 'react';

const Info = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)">
                <path d="M11,18 L13,18 L13,10 L11,10 L11,18 Z M11,8 L13,8 L13,6 L11,6 L11,8 Z M12,22 C17.522,22 22,17.522 22,12 C22,6.478 17.522,2 12,2 C6.478,2 2,6.478 2,12 C2,17.522 6.478,22 12,22 L12,22 Z" />
            </g>
        </g>
    </svg>
);

export default Info;
