import { React } from '../../dependencies';

const DoneSelected = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)">
                <circle cx="12" cy="12" r="10" />
                <polygon
                    fill="#FFFFFF"
                    points="11 13.5859 8.707 11.2929 7.293 12.7069 11 16.4139 16.707 10.7069 15.293 9.2929"
                />
            </g>
        </g>
    </svg>
);

export default DoneSelected;
