import { React, PropTypes, UI, CreateTransactionButton, FormattedMessage } from '../../../dependencies';

const { Card, Title, TitleSizes, Currency, Label, LabelSizes } = UI;

const Item = ({
    styles,
    item: { name, id, price, priceDescription, imageOriginalUrl, imageSmallUrl, imageLargeUrl },
}) => (
    <div className={styles.container}>
        <Card>
            <div className={styles.row}>
                <div className={styles.imageWrapper}>
                    {imageOriginalUrl ? (
                        <img
                            src={imageOriginalUrl || imageLargeUrl || imageSmallUrl}
                            alt={name}
                            className={styles.image}
                            loading="lazy"
                        />
                    ) : (
                        <span className={styles.imageFallback} role="img">
                            🤷‍♂️
                        </span>
                    )}
                </div>

                <div>
                    <Title size={TitleSizes.H3}>{name}</Title>
                    <Label size={LabelSizes.LARGE}>
                        <Currency value={price} placeholder={<FormattedMessage id="product.forFree" />} />
                        {priceDescription && (
                            <>
                                {' / '}
                                <Label>{priceDescription}</Label>
                            </>
                        )}
                    </Label>
                </div>

                <div className={styles.button}>
                    <CreateTransactionButton productId={id} />
                </div>
            </div>
        </Card>
    </div>
);

Item.propTypes = {
    styles: PropTypes.shape().isRequired,
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        priceDescription: PropTypes.string,
        imageLargeUrl: PropTypes.string,
        imageOriginalUrl: PropTypes.string,
        imageSmallUrl: PropTypes.string,
        name: PropTypes.string.isRequired,
    }).isRequired,
};

export default Item;
