export default ({ theme }) => ({
    '& .ant-input': {
        height: '3.5rem',
        padding: '.6rem 1rem .4rem 1rem',

        fontFamily: theme.fontFamily,
        fontSize: '1rem',
        fontWeight: '500',
        lineHeight: '1.5rem',

        display: 'flex',
        alignItems: 'center',

        color: theme.colors.black,
        backgroundColor: `#fff`,
        border: 'none',
        boxShadow: '0 2px 0 rgba(0, 0, 0, 0.015)',
        borderRadius: '6px',

        ':hover': {
            border: 'none',
            boxShadow: 'none',
        },
        ':focus': {
            border: 'none',
            boxShadow: 'none',
        },
    },
    '& .ant-input:not(:last-child)': {
        paddingRight: '3.5rem',
    },
    '& .ant-input-suffix': {
        right: '1rem',
        zIndex: 0,
    },
});
