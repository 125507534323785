import { React } from '../../dependencies';

const ChevronDown = props => (
    <svg viewBox="0 0 21 12" {...props}>
        <g transform="translate(10.500000, 6.000000) rotate(-270.000000) translate(-10.500000, -6.000000) translate(5.000000, -4.000000)">
            <path d="M1.3896,0 L-0.0004,1.405 L8.2616,10.013 L7.3796,10.932 L7.3846,10.926 L0.0446,18.573 L1.4136,20 C3.4426,17.887 9.1066,11.986 10.9996,10.013 C9.5936,8.547 10.9646,9.977 1.3896,0" />
        </g>
    </svg>
);

export default ChevronDown;
