import { React, PropTypes, Transactions, UI, Balance, Logout, useResetScrollOnMount } from '../../dependencies';

import { TransactionsList } from '../../modules/transactions-list';

const { Container, Space } = UI;

const TransactionsPage = () => {
    useResetScrollOnMount();
    const getApi = Transactions.useFetchTransactions();

    // TODO: add list of transactions in progress (those that aren't in the history collection yet)

    return (
        <Container>
            <Balance />
            <Space bottom={24} />
            <TransactionsList />
            <Space bottom={24} />
            <Logout />
        </Container>
    );
};

export default TransactionsPage;
