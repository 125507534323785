import { React } from '../../dependencies';

const Circle = props => (
    <svg viewBox="0 0 12 12" {...props}>
        <g>
            <path d="M6,0 C2.687,0 0,2.687 0,6 C0,9.313 2.687,12 6,12 C9.313,12 12,9.313 12,6 C12,2.687 9.313,0 6,0 M6,2 C8.206,2 10,3.794 10,6 C10,8.206 8.206,10 6,10 C3.794,10 2,8.206 2,6 C2,3.794 3.794,2 6,2" />
        </g>
    </svg>
);

export default Circle;
