import { React } from '../../dependencies';

const ArrowLeft = props => (
    <svg viewBox="0 0 16 20" {...props}>
        <g id="Styleguide" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g id="Desktop-HD" transform="translate(-104.000000, -5698.000000)">
                <g id="ic/attachment" transform="translate(100.000000, 5696.000000)">
                    <path
                        d="M2,4 L2,20 L22,20 L22,10.209 L16,4 L2,4 Z M4,6 L14,6 L14,12 L20,12 L20,18 L4,18 L4,6 Z"
                        id="Fill-1"
                        transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) "
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);

export default ArrowLeft;
