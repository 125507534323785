import { React, useSelector, useDispatch, useCurrentRef, AuthSelectors } from '../dependencies';

import { fetchUserRequest, fetchUserCancel } from '../services/actions';
import { getUserApiSelector } from '../services/selectors';

export function useFetchUserBalance() {
    const user = useSelector(AuthSelectors.authUserSelector);
    const userId = user.email;

    const { inProgress, success, error } = useSelector(state => getUserApiSelector(state, userId));
    const dispatch = useDispatch();

    const requestUser = () => {
        if (!inProgress) {
            dispatch(fetchUserRequest(userId));
        }
    };

    const inProgressRef = useCurrentRef(inProgress);

    React.useEffect(() => {
        requestUser();

        return () => {
            if (inProgressRef.current) {
                dispatch(fetchUserCancel(userId));
            }
        };
    }, []);

    return {
        inProgress,
        success,
        error: {
            message: error,
            retry: requestUser,
        },
    };
}
