export const navbar = ({ theme: { colors } }) => ({
    position: 'fixed',
    width: '100%',
    bottom: 0,
    height: 'calc(4rem + env(safe-area-inset-bottom))',
    paddingBottom: 'env(safe-area-inset-bottom)',
    backgroundColor: colors.veryLightPink,
    boxShadow: '0 0 13px 5px rgba(102, 153, 255, 0.15)',
});

export const container = () => ({
    width: '100%',
    position: 'relative',
    margin: '0 auto',
    padding: '0 1rem',
    overflow: 'hidden',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(2, 1fr)',
    height: '100%',
});
