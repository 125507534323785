import { React, PropTypes, childrenPropType } from '../../../dependencies';

const DynamicContainer = ({ styles, children }) => <div className={styles.container}>{children}</div>;

DynamicContainer.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,

    // eslint-disable-next-line
    columns: PropTypes.number,
    children: childrenPropType,
};

DynamicContainer.defaultProps = {
    columns: 10,
    children: null,
};

export default DynamicContainer;
