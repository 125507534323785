export const card = ({ theme: { colors } }) => ({
    textAlign: 'center',
    padding: '5rem 1.5rem',
    justifyContent: 'center',
    display: 'grid',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: '0.25rem',
    gridRowGap: '0.5rem',
    margin: '0 1rem',
});

export const icon = ({ theme: { colors } }) => ({
    height: 20,
    fill: colors.red,
    margin: '0 auto',
});

export const buttonExtend = {
    button: () => ({
        margin: 'auto',
    }),
};
