import { types } from '../actions';

const initialState = {};

export default function messagesReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_MESSAGE_RESOLVE: {
            const {
                meta: { id },
                payload,
            } = action;

            return {
                ...state,
                [id]: {
                    ...payload,
                    id,
                    visible: true,
                },
            };
        }

        case types.HIDE_MESSAGE: {
            const { id } = action.meta;

            return {
                ...state,
                [id]: {
                    ...state[id],
                    visible: false,
                },
            };
        }

        case types.REMOVE_MESSAGE: {
            const { id } = action.meta;

            if (!state[id]) {
                return state;
            }

            const nextState = { ...state };
            delete nextState[id];

            return nextState;
        }

        case types.CLEAR_MESSAGES:
            return initialState;

        default:
            return state;
    }
}
