import { React, PropTypes } from '../../../dependencies';

import Title, { TitleSizes } from '../../Title';

const Header = ({ styles, children, rules, button }) => {
    return (
        <header className={styles.header}>
            <Title extend={rules.extendTitle} size={TitleSizes.H2}>
                {children}
            </Title>
            {button}
        </header>
    );
};

Header.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    children: PropTypes.node.isRequired,
    button: PropTypes.node,
};

Header.defaultProps = {
    button: null,
};

export default Header;
