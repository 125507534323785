import { merge } from 'lodash';
import { isEnvDevelopment } from 'constants/index';

const { NODE_ENV, REACT_APP_NAME } = process.env;
// eslint-disable-next-line
const envConfig = require(`./config.${NODE_ENV}.js`).default;

const defaults = {
    appName: REACT_APP_NAME,
    devTools: isEnvDevelopment,
    sentry: {
        dsn: 'https://2c35b9abf6034a248019382001faa865@o481746.ingest.sentry.io/6129598',
    },
    routes: {
        home: '/',
        transactions: '/transactions',
    },
    collections: {
        products: 'products',
        transactions: 'transactions',
        history: 'history',
        users: 'users',
        favouriteProduct: 'stats/favouriteProducts/:productId',
        favouriteProducts: 'stats/favouriteProducts',
    },
    currencyFormat: {
        currency: 'CZK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    modals: {
        addTransaction: 'addTransaction',
    },
    customProductId: 'customProduct',
};

export default merge(defaults, envConfig);
