import { React, useDispatch, UI, FormattedMessage, Modals, Config } from '../../../dependencies';

import NewTransactionModal from './NewTransactionModal';

const { Button, ButtonSizes, ButtonTypes } = UI;
const { Actions } = Modals;

const AddTransaction = () => {
    const dispatch = useDispatch();

    return (
        <>
            <Button
                onClick={() => dispatch(Actions.openModal(Config.modals.addTransaction))}
                type={ButtonTypes.PRIMARY}
                size={ButtonSizes.SMALL}
            >
                <FormattedMessage id="transactions.add" />
            </Button>
            <NewTransactionModal />
        </>
    );
};

export default React.memo(AddTransaction);
