import { React } from '../../dependencies';

const Password = props => (
    <svg viewBox="0 0 12 20" {...props}>
        <g>
            <circle cx="6" cy="6" r="6" />
            <rect x="3" y="8" width="6" height="12" rx="3" />
        </g>
    </svg>
);

export default Password;
