import { basicApiReducer, containerReducer } from '../../../../dependencies';
import { types } from '../../../actions';

const actionTypes = {
    REQUEST: types.FETCH_USER_REQUEST,
    SUCCESS: types.FETCH_USER_SUCCESS,
    FAILURE: types.FETCH_USER_FAILURE,
    CANCEL: types.FETCH_USER_CANCEL,
};

const apiReducer = basicApiReducer({
    actionTypes,
});

export default containerReducer({
    childReducer: apiReducer,
    actionTypes: Object.values(actionTypes),
    selectors: {
        itemId: action => action.meta.userId,
    },
});
