import { createSelector } from '../../dependencies';

export const selectProducts = state => state.entities.products;

export const productsFilterSelector = state => state.productsFilter;

export const productsListSelector = createSelector(
    [selectProducts, productsFilterSelector],
    ({ byId, ids }, filterValue) => ids.map(id => byId[id]).filter(item => item.normalizedFilter.includes(filterValue)),
);

export const productSelector = (state, id) => state.entities.products.byId[id];
