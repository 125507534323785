import { React, PropTypes, childrenPropType } from '../../dependencies';

const Space = ({ styles, children }) => <div className={styles.space}>{children}</div>;

Space.propTypes = {
    styles: PropTypes.shape().isRequired,
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    children: childrenPropType,
};

Space.defaultProps = {
    top: 0,
    bottom: 0,
    children: null,
};

export default Space;
