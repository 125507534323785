import { React } from '../../dependencies';

const Document = props => (
    <svg viewBox="0 0 16 20" {...props}>
        <g>
            <path
                d="M-2,2 L-2,18 L18,18 L18,8.209 L12,2 L-2,2 Z M0,4 L10,4 L10,10 L16,10 L16,16 L0,16 L0,4 Z"
                transform="translate(8.000000, 10.000000) scale(-1, 1) rotate(-90.000000) translate(-8.000000, -10.000000) "
            />
        </g>
    </svg>
);

export default Document;
