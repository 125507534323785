import { React } from '../dependencies';

export function useCurrentRef(value) {
    const ref = React.useRef(value);

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref;
}
