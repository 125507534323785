import { React, PropTypes, FormattedMessage, UI, useDispatch, Auth, useSelector } from '../../dependencies';

import * as ExtendStyles from './Login.extendStyles';

const { Title, TitleSizes, Text, TextSizes, Button, ButtonTypes } = UI;

const Login = ({ styles }) => {
    const dispatch = useDispatch();
    const signInApi = useSelector(Auth.Selectors.signInApiSelector);

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <Title size={TitleSizes.H1} extend={ExtendStyles.title}>
                    <FormattedMessage id="loginForm.heading" />
                </Title>
                <Text size={TextSizes.SMALL} extend={ExtendStyles.text}>
                    <FormattedMessage id="loginForm.perex" />
                </Text>

                <Button
                    onClick={() => dispatch(Auth.Actions.signInRequest())}
                    type={ButtonTypes.PRIMARY}
                    extend={ExtendStyles.button}
                    loading={signInApi.inProgress}
                >
                    <FormattedMessage id="button.login" />
                </Button>
            </div>
        </div>
    );
};

Login.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default React.memo(Login);
