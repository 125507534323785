import { React, PropTypes, UI, childrenPropType, combineRules } from '../../dependencies';

const { Card } = UI;

const Modal = ({ rules, styles, children, closeModal, cardExtend }) => {
    return (
        <div className={styles.container}>
            <div className={styles.overlay} onClick={closeModal} role="button" tabIndex="0" />
            <Card
                extend={{
                    card: combineRules(rules.extendCard, cardExtend),
                }}
            >
                {children}
            </Card>
        </div>
    );
};

Modal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    children: childrenPropType.isRequired,
};

export default Modal;
