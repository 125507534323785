export const container = () => ({
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const card = () => ({
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingTop: '3.5rem',
    paddingBottom: '3.5rem',
    background: '#fff',
    width: '17rem',
    borderRadius: '1.25rem',
    textAlign: 'center',
});
