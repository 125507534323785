import Sizes from './Text.sizes';

export const text = ({ size, theme: { colors }, link }) => {
    const base = {
        display: 'block',
        color: colors.darkIndigo,
        textAlign: 'left',
        fontWeight: 500,
        textDecoration: link ? 'underline' : 'unset',

        onHover: {
            color: link ? colors.cornflower : 'unset',
        },
    };

    switch (size) {
        case Sizes.NORMAL:
            return Object.assign(base, {
                fontSize: '1rem',
                lineHeight: 1.5,
            });

        case Sizes.SMALL:
            return Object.assign(base, {
                fontSize: '0.88rem',
                lineHeight: 1.57,
                letterSpacing: '0.1px',
            });

        default:
            return base;
    }
};
