import { React, PropTypes, useSelector, Selectors } from '../../dependencies';

import Item from './Item';

const List = ({ styles }) => {
    const items = useSelector(Selectors.productsListSelector);

    return (
        <div className={styles.container}>
            {items.map(item => (
                <Item key={item.id} item={item} />
            ))}
        </div>
    );
};

List.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
};

export default List;
