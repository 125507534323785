import { React, FelaComponent, PropTypes, Icons, Input, noop } from '../../dependencies';

import ClearButton from './ClearButton';

import style from './SearchInput.style';

const DEFAULT_SEARCH = '';

const SearchInput = ({ onChange, onClear, ...props }) => {
    const [value, setValue] = React.useState(DEFAULT_SEARCH);
    return (
        <FelaComponent style={style}>
            <Input
                {...props}
                value={value}
                onChange={e => {
                    e.persist();
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                suffix={
                    value ? (
                        <ClearButton
                            onClick={() => {
                                setValue(DEFAULT_SEARCH);
                                onClear(DEFAULT_SEARCH);
                            }}
                        />
                    ) : (
                        <Icons.Search />
                    )
                }
            />
        </FelaComponent>
    );
};

SearchInput.propTypes = {
    onChange: PropTypes.func,
    onClear: PropTypes.func,
};

SearchInput.defaultProps = {
    onChange: noop,
    onClear: noop,
};

export default SearchInput;
