import { React, FelaComponent, PropTypes, SearchInput, useIntl, useFetchProducts } from '../../dependencies';

import style from './Filter.style';

const Filter = ({ handleChange, handleClear }) => {
    const intl = useIntl();
    const api = useFetchProducts();

    return (
        <FelaComponent style={style}>
            <SearchInput
                name="filter"
                placeholder={intl.formatMessage({
                    id: 'search',
                })}
                onChange={handleChange}
                onClear={handleClear}
                disabled={api.inProgress}
            />
        </FelaComponent>
    );
};

Filter.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleClear: PropTypes.func.isRequired,
};

export default Filter;
