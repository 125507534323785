export const button = ({ theme: { colors } }) => ({
    backgroundColor: colors.white,
    borderRadius: '0.38rem',
    minWidth: 271,

    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1.5rem 1fr 1.5rem',
    gridColumnGap: '1rem',
    padding: '1.25rem 1.5rem',
});

export const iconWrapper = () => ({
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const iconChevronRight = ({ theme: { colors } }) => ({
    fill: colors.darkIndigo,
    height: '0.88rem',
    width: '0.53rem',
});
