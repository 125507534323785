import { get } from '../dependencies';

import { ErrorUIMessages } from '../config';

function getApiErrorData(error) {
    return get(error, 'response.data.error');
}

function isApiError(error) {
    return Boolean(getApiErrorData(error));
}

function parseError(error) {
    const { status, errorCode: code } = getApiErrorData(error);

    return {
        status,
        code,
    };
}

function findErrorMessage({ status, code }, errorUIMessages) {
    return get(errorUIMessages, `code.${code}`) || get(errorUIMessages, `status.${status}`) || null;
}

// 1. If `error` isn't `ApiError`, return fallback message.
// 2. Parse `error`, extract `code` and `status`
// 3. Find with the `code` or `status` custom error message in `errorMessages` object.
// 4. If no custom message has been found, try to find default error message in `ErrorUIMessage` (placed in `../config` file)
// 5. If no default error message has been found, return fallback message.
export function createUIErrorMessage(error, errorUIMessages = {}) {
    if (!isApiError(error)) {
        return errorUIMessages.fallback || ErrorUIMessages.fallback;
    }

    const errorKeys = parseError(error);

    const errorMessage = findErrorMessage(errorKeys, errorUIMessages) || findErrorMessage(errorKeys, ErrorUIMessages);

    return errorMessage || errorUIMessages.fallback || ErrorUIMessages.fallback;
}

export function createUIErrorMessageFactory(errorUIMessages = {}) {
    return error => createUIErrorMessage(error, errorUIMessages);
}
