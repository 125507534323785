import { React, PropTypes, FormattedMessage, UI, connectFela } from '../../dependencies';

import { errorPropType } from '../propTypes';

import * as Styles from './ErrorWithAction.styles';

const { Button, ButtonSizes, ButtonTypes, Icons, Container, Text } = UI;

function ErrorWithAction({ error, onClick, styles, buttonTextId }) {
    return (
        <Container>
            <div className={styles.card}>
                <Icons.CrazyFace className={styles.icon} />

                <Text>
                    <FormattedMessage {...error} />
                </Text>

                {onClick && (
                    <div>
                        <Button
                            extend={Styles.buttonExtend}
                            type={ButtonTypes.DEFAULT}
                            size={ButtonSizes.SMALL}
                            onClick={() => onClick()}
                        >
                            <FormattedMessage id={buttonTextId} />
                        </Button>
                    </div>
                )}
            </div>
        </Container>
    );
}

ErrorWithAction.propTypes = {
    error: errorPropType.isRequired,
    onClick: PropTypes.func,
    styles: PropTypes.shape().isRequired,
    buttonTextId: PropTypes.string,
};

ErrorWithAction.defaultProps = {
    onClick: null,
    buttonTextId: 'error.button.retry',
};

export default connectFela(Styles)(ErrorWithAction);
