import { ButtonSizes } from './Button.consts';

export const button = ({ size }) => {
    const base = {
        display: 'flex',
        alignItems: 'center',
    };

    switch (size) {
        case ButtonSizes.LARGE:
        case ButtonSizes.NORMAL:
            Object.assign(base, {
                height: '2.875rem',
            });
            break;

        case ButtonSizes.SMALL:
            Object.assign(base, {
                height: '2rem',
                borderRadius: 4,
                padding: '0 19px',
            });
            break;

        default:
    }

    return base;
};
