import { types } from '../../actions';

const initialState = {
    byId: {},
    ids: [],
};

export default function productsReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_PRODUCTS_SUCCESS:
            return action.payload;

        default:
            return state;
    }
}
