export const alphaLocaleCompare = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());

export const normalizeInput = input =>
    String(input)
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

export const filterOption = (input, option) => {
    return normalizeInput(option.props.children).indexOf(normalizeInput(input)) >= 0;
};

export const immutablePropDelete = (src = {}, propName) => {
    if (src[propName]) {
        const copy = { ...src };

        delete copy[propName];

        return copy;
    }

    return src;
};

export const immutableInsertIds = (dest = [], payload = [], leftOffset = 0) => {
    const destCopy = [...dest];

    payload.forEach((item, index) => {
        const destIndex = leftOffset + index;
        destCopy[destIndex] = item;
    });

    return destCopy;
};

export function composePayload(querySnap) {
    const byId = {};
    const ids = [];

    querySnap.forEach(doc => {
        const data = doc.data();
        const { id } = doc;
        byId[id] = {
            id,
            ...data,
        };
        ids.push(id);
    });

    return {
        byId,
        ids,
    };
}
