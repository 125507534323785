import { React } from '../../dependencies';

const Bin = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-2.000000, -2.000000)">
                <path d="M7,2 L7,6 L2,6 L2,7.999 L4.999,7.999 L4.999,22 L19,22 L19,7.999 L21.999,7.999 L21.999,6 L17,6 L17,2 L7,2 Z M9,6 L14.999,6 L14.999,4 L9,4 L9,6 Z M7,20 L17,20 L17,7.999 L7,7.999 L7,20 Z M12.999,18 L14.999,18 L14.999,10 L12.999,10 L12.999,18 Z M9,18 L11,18 L11,10 L9,10 L9,18 Z" />
            </g>
        </g>
    </svg>
);

export default Bin;
