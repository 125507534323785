import { types } from '../actions';

export default function filterReducer(state = '', action) {
    switch (action.type) {
        case types.FILTER_PRODUCTS:
            return action.payload;

        default:
            return state;
    }
}
