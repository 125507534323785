import types from './types';

export const fetchUserRequest = userId => ({
    type: types.FETCH_USER_REQUEST,
    meta: { userId },
});

export const fetchUserSuccess = (userId, payload) => ({
    type: types.FETCH_USER_SUCCESS,
    meta: { userId },
    payload,
});

export const fetchUserFailure = (userId, error) => ({
    type: types.FETCH_USER_FAILURE,
    meta: { userId },
    error,
});

export const fetchUserCancel = userId => ({
    type: types.FETCH_USER_CANCEL,
    meta: { userId },
});
