import { React, PropTypes, childrenPropType } from '../../dependencies';

import Sizes from './Text.sizes';

const Text = ({ styles, children, size }) => (
    <span className={styles.text} size={size}>
        {children}
    </span>
);

Text.propTypes = {
    styles: PropTypes.shape().isRequired,
    children: childrenPropType.isRequired,
    size: PropTypes.oneOf(Object.values(Sizes)),
    // eslint-disable-next-line
    link: PropTypes.bool,
};

Text.defaultProps = {
    size: Sizes.NORMAL,
    link: false,
};

export default Text;
