import { React } from '../../dependencies';

const Signature = props => (
    <svg viewBox="0 0 22 23" {...props}>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g id="upload-drag/signature" transform="translate(-180.000000, -24.000000)">
                <g id="Group-2" transform="translate(145.000000, 24.000000)">
                    <g id="Group" transform="translate(35.000000, 0.000000)">
                        <polygon
                            id="Path"
                            points="2 0 2 13.999 4 13.999 4 2 14.001 2 14.001 7.999 20 7.999 20 18 8 18 8 20 22 20 22 6.079 16 0"
                        ></polygon>
                        <polygon
                            id="Path"
                            points="2 16 2 18.001 1.02584607e-13 18.001 1.02584607e-13 20.001 2 20.001 2 22.001 4 22.001 4 20.001 6.001 20.001 6.001 18.001 4 18.001 4 16"
                        ></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Signature;
