import { strictObjectAccess } from '@ackee/redux-utils/es';

import * as dimensionsVars from './dimensions';
import { locals as colorsVars } from './colors.less';
import { locals as textVars } from './text.less';
import { locals as zIndexVars } from './zIndex.less';

export const colors = strictObjectAccess(colorsVars, false);
export const text = strictObjectAccess(textVars, false);
export const dimensions = strictObjectAccess(dimensionsVars, false);
export const zIndex = strictObjectAccess(zIndexVars, false);
