import { React } from '../../dependencies';

const Darlings = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g transform="translate(0.000000, -1.000000)">
            <g transform="translate(12.000000, 0.037900)">
                <path d="M6.5137,6.2848 C4.0187,8.6978 3.6547,8.4138 1.4107,6.2148 C-2.0613,2.8128 1.7037,-0.8752 3.8437,1.9878 C3.9217,2.0918 4.0757,2.0888 4.1527,1.9848 C6.2647,-0.8582 10.1347,2.7848 6.5137,6.2848" />
            </g>
            <g transform="translate(0.000000, 6.037900)">
                <path d="M8.042,6.8844 C8.028,6.8844 8.015,6.8824 8,6.8824 C7.985,6.8824 7.972,6.8844 7.958,6.8844 C6.875,6.8614 6,5.9764 6,4.8884 C6,3.7854 6.897,2.8884 8,2.8884 C9.103,2.8884 10,3.7854 10,4.8884 C10,5.9764 9.125,6.8614 8.042,6.8844 M11.026,7.4774 C11.625,6.7784 12,5.8814 12,4.8884 C12,2.6794 10.209,0.8884 8,0.8884 C5.791,0.8884 4,2.6794 4,4.8884 C4,5.8804 4.375,6.7784 4.974,7.4774 C2.057,8.6674 0,11.6354 0,14.9824 C0,15.0224 0.006,14.9824 0.006,14.9824 L2,14.9824 C2,11.6884 4.669,8.9064 7.958,8.8844 C7.972,8.8844 7.985,8.8884 8,8.8884 C8.015,8.8884 8.028,8.8844 8.042,8.8844 C11.331,8.9064 14,11.6884 14,14.9824 L16,14.9824 C16,11.6354 13.943,8.6694 11.026,7.4774" />
            </g>
        </g>
    </svg>
);

export default Darlings;
