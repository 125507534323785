export default ({ theme: { colors } }) => ({
    paddingTop: 0,
    marginBottom: '2.5rem',

    borderBottom: `1px solid ${colors.paleGrey}`,

    '& .ant-input': {
        height: '3.5rem',

        paddingTop: '1rem',
        paddingRight: '1rem',

        borderRadius: '0.5rem',

        '&::placeholder': {
            color: colors.blueyGrey,
        },
    },
});
