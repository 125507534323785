import { React, PropTypes, useSelector, Transactions, UI, FormattedMessage } from '../../dependencies';

import Item from './Item';
import AddTransanction from './AddTransanction';

const { Card } = UI;

const List = ({ styles }) => {
    const items = useSelector(Transactions.Selectors.transactionsListSelector);

    return (
        <Card header={<FormattedMessage id="navbar.item.transactions" />} button={<AddTransanction />}>
            <div className={styles.container}>
                {items.map((item, index) => (
                    <Item key={item.id} item={item} isLast={index >= items.length - 1} />
                ))}
            </div>
        </Card>
    );
};

List.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
};

export default List;
