import { React, PropTypes, childrenPropType } from '../../dependencies';

import Sizes from './Label.sizes';

const Label = ({ styles, children }) => <span className={styles.label}>{children}</span>;

Label.propTypes = {
    styles: PropTypes.shape().isRequired,
    size: PropTypes.oneOf(Object.values(Sizes)),
    children: childrenPropType.isRequired,
};

Label.defaultProps = {
    size: Sizes.NORMAL,
};

export default Label;
