import React from 'react';

const SoundOff = props => (
    <svg viewBox="0 2 24 20" {...props}>
        <g>
            <path d="M2,14.5 L4,14.5 L4,10.5 L2,10.5 L2,14.5 Z M6,9.236 C8.921,7.775 11.164,6.654 14,5.236 L14,18.764 C11.165,17.346 8.921,16.225 6,14.764 L6,9.236 Z M0,8.5 L0,16.5 L3.667,16.5 L16,22 L16,2 L3.667,8.5 L0,8.5 Z" />
        </g>
    </svg>
);

export default SoundOff;
