import Sizes from './Title.sizes';

export const title = ({ size, link, theme: { colors } }) => {
    const base = {
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'unset',
        color: colors.darkIndigo,
        marginBottom: '0.25rem',
    };

    if (link) {
        base.textDecoration = 'underline';
    }

    switch (size) {
        case Sizes.H1:
            return Object.assign(base, {
                fontSize: '1.5rem',
                fontWeight: 700,
                lineHeight: 1.29,
            });

        case Sizes.H2:
            return Object.assign(base, {
                fontSize: '1.13rem',
                fontWeight: 700,
                lineHeight: 1.3,
            });

        case Sizes.H3:
            return Object.assign(base, {
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: 1.31,
            });

        case Sizes.H4:
            return Object.assign(base, {
                fontSize: '0.88rem',
                fontWeight: 400,
                lineHeight: 1.36,
                color: colors.blueyGrey,
            });

        default:
            return base;
    }
};
