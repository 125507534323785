import React from 'react';

const HappyFace = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <path d="M10,18 C5.589,18 2,14.411 2,10 C2,5.589 5.589,2 10,2 C14.411,2 18,5.589 18,10 C18,14.411 14.411,18 10,18 M10,0 C4.477,0 0,4.478 0,10 C0,15.522 4.477,20 10,20 C15.523,20 20,15.522 20,10 C20,4.478 15.523,0 10,0" />
        <path d="M6.5791,8.5 C7.4071,8.5 8.0791,7.828 8.0791,7 C8.0791,6.172 7.4071,5.5 6.5791,5.5 C5.7511,5.5 5.0791,6.172 5.0791,7 C5.0791,7.828 5.7511,8.5 6.5791,8.5" />
        <path d="M13.4189,8.5 C14.2469,8.5 14.9189,7.828 14.9189,7 C14.9189,6.172 14.2469,5.5 13.4189,5.5 C12.5899,5.5 11.9189,6.172 11.9189,7 C11.9189,7.828 12.5899,8.5 13.4189,8.5" />
        <path d="M9.999,14.0342 C8.344,14.0342 6.832,12.9972 6.235,11.4542 L4.37,12.1752 C5.262,14.4832 7.524,16.0342 9.999,16.0342 C12.474,16.0342 14.736,14.4832 15.628,12.1752 L13.763,11.4542 C13.166,12.9972 11.654,14.0342 9.999,14.0342" />
    </svg>
);

export default HappyFace;
