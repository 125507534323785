import { React } from '../../dependencies';

const Dashboard = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g>
            <path d="M10,17.9814 C5.941,17.9814 2.606,14.9494 2.096,11.0274 L6,11.0354 L6,11.0284 L8.417,8.5904 C11.085,13.1664 10.403,11.9974 11.699,14.2184 L14.907,11.0004 L17.925,11.0004 C17.414,15.0004 14.059,17.9814 10,17.9814 M10,1.9624 C14.084,1.9624 17.458,5.0004 17.936,9.0004 L14,9.0004 L14,9.0464 L12.04,11.0434 L9.793,7.1764 L9.799,7.1704 L8.754,5.3794 L7.342,6.7934 L7.351,6.7894 L5.123,9.0004 L2.064,9.0004 C2.542,5.0004 5.916,1.9624 10,1.9624 M10,0.0004 C4.477,0.0004 0,4.4774 0,10.0004 C0,15.5234 4.477,20.0004 10,20.0004 C15.523,20.0004 20,15.5234 20,10.0004 C20,4.4774 15.523,0.0004 10,0.0004" />
        </g>
    </svg>
);

export default Dashboard;
