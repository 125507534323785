import { React, PropTypes, Log } from '../../dependencies';

import Message from '../Message';
import MessageContent, { MessageContentPropTypes } from '../MessageContent';

class MessageList extends React.Component {
    componentDidCatch(error) {
        const { clearMessages } = this.props;

        // Remove broken message/s from the stack.
        clearMessages();

        Log.error(error);
    }

    render() {
        const { styles, messages } = this.props;

        if (messages.length === 0) {
            return null;
        }

        return (
            <div className={styles.list}>
                {messages.map(({ id, level, message, button, visible }) => (
                    <Message key={id} visible={visible}>
                        {state => (
                            <MessageContent
                                {...{
                                    id,
                                    state,
                                    level,
                                    message,
                                    button,
                                }}
                            />
                        )}
                    </Message>
                ))}
            </div>
        );
    }
}

MessageList.propTypes = {
    styles: PropTypes.shape().isRequired,
    messages: PropTypes.arrayOf(PropTypes.shape(MessageContentPropTypes)).isRequired,
    clearMessages: PropTypes.func.isRequired,
};

export default MessageList;
