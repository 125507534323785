import { React, Messages } from '../dependencies';

import Firewall from './Firewall';

export default function App() {
    return (
        <>
            <Firewall />

            <Messages />
        </>
    );
}
