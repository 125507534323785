import { React, PropTypes, UI, useIntl, TextInput, FormattedMessage, CustomPropTypes } from '../../../../dependencies';

const { Label } = UI;

const FormInput = ({ styles, theme, rules, placeholder, label: labelMessage, ...rest }) => {
    const intl = useIntl();
    return (
        <>
            <label htmlFor={rest.name} className={styles.label}>
                <Label>
                    <FormattedMessage {...labelMessage} />
                </Label>
            </label>
            <TextInput {...rest} placeholder={intl.formatMessage(placeholder)} />
        </>
    );
};

FormInput.propTypes = {
    styles: PropTypes.shape().isRequired,
    value: CustomPropTypes.value,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: CustomPropTypes.message.isRequired,
    label: CustomPropTypes.message.isRequired,
};

export default FormInput;
