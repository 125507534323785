import { React, PropTypes } from '../../dependencies';
import Sidebar from './Sidebar';
import Main from './Main';
import Container from './Container';

const Layout = ({ children, sidebarContent }) => (
    <>
        <Container>
            <Sidebar>{sidebarContent}</Sidebar>
            <Main>{children}</Main>
        </Container>
    </>
);

Layout.propTypes = {
    children: PropTypes.element.isRequired,
    sidebarContent: PropTypes.element.isRequired,
};

export default Layout;
