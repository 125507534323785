import { basicApiReducer } from '../../../../dependencies';
import { types } from '../../../actions';

const actionTypes = {
    REQUEST: types.FETCH_TRANSACTIONS_REQUEST,
    SUCCESS: types.FETCH_TRANSACTIONS_SUCCESS,
    FAILURE: types.FETCH_TRANSACTIONS_FAILURE,
    CANCEL: types.FETCH_TRANSACTIONS_CANCEL,
};

const apiReducer = basicApiReducer({
    actionTypes,
});

export default apiReducer;
