import { React, PropTypes, connectFela, childrenPropType, FelaComponent } from '../../dependencies';

import * as Styles from './Loader.styles';

import FelaKeyframe from '../FelaKeyFrame';

const heartBeat = () => ({
    '10%': {
        transform: 'scale(1.25)',
        opacity: '0.6',
    },
});

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

const Emojis = [
    <span role="img" aria-label="apple">
        🍎
    </span>,
    <span role="img" aria-label="">
        🍌
    </span>,
    <span role="img" aria-label="">
        🥩
    </span>,
    <span role="img" aria-label="">
        🍕
    </span>,
    <span role="img" aria-label="">
        🌮
    </span>,
    <span role="img" aria-label="">
        🥝
    </span>,
    <span role="img" aria-label="">
        🍻
    </span>,
    <span role="img" aria-label="">
        🥙
    </span>,
    <span role="img" aria-label="">
        🥂
    </span>,
    <span role="img" aria-label="">
        🍭
    </span>,
    <span role="img" aria-label="">
        🍪
    </span>,
    <span role="img" aria-label="">
        🍩
    </span>,
];

function getRandomFoodEmoji() {
    return Emojis[getRandomInt(Emojis.length)];
}

const Loader = ({ children, show, styles }) => {
    const emoji = React.useRef(getRandomFoodEmoji());

    if (!show) {
        return <>{children}</>;
    }

    return (
        <div className={styles.loader}>
            <FelaKeyframe keyframe={heartBeat}>
                {animationName => (
                    <FelaComponent style={Styles.heart} animationName={animationName}>
                        {({ className }) => <span className={className}>{emoji.current}</span>}
                    </FelaComponent>
                )}
            </FelaKeyframe>
        </div>
    );
};

Loader.propTypes = {
    children: childrenPropType,
    show: PropTypes.bool,
    styles: PropTypes.shape().isRequired,
    inline: PropTypes.bool,
};

Loader.defaultProps = {
    show: true,
    children: null,
    inline: false,
};

export default connectFela(Styles)(Loader);
