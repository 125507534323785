import types from './types';

export { types };

export const installed = () => ({
    type: types.INSTALLED,
});

export const updated = () => ({
    type: types.UPDATED,
});

export const refreshPage = () => ({
    type: types.REFRESH_PAGE,
});
