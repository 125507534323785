import {
    React,
    PropTypes,
    useFetchUserBalance,
    Selectors,
    useSelector,
    UI,
    FormattedMessage,
} from '../../dependencies';

const { Card, Title, TitleSizes, Currency } = UI;

const Balance = ({ styles, rules }) => {
    const api = useFetchUserBalance();
    const balance = useSelector(Selectors.authUserBalanceSelector);

    return (
        <Card header={<FormattedMessage id="balance.title" />}>
            <div className={styles.balance}>
                <Title size={TitleSizes.H2} extend={rules.extendTitle}>
                    <Currency value={balance} isEmpty={!Number.isFinite(balance)} />
                </Title>
            </div>
        </Card>
    );
};

Balance.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default Balance;
