import { connect } from '../dependencies';

import { clearMessages } from '../services/actions';
import { messagesSelector } from '../services/selectors';

import MessageList from '../components/MessageList';

const mapStateToProps = state => ({
    messages: messagesSelector(state),
});

const mapDispatchToProps = {
    clearMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
