import { React, FelaComponent, PropTypes, Input, noop } from '../../dependencies';

import style from './TextInput.style';

const TextInput = ({ onChange, value, ...props }) => {
    return (
        <FelaComponent style={style}>
            <Input {...props} value={value} onChange={e => onChange(e.target.value)} />
        </FelaComponent>
    );
};

TextInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
};

TextInput.defaultProps = {
    onChange: noop,
    value: '',
};

export default TextInput;
