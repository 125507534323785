import { strictObjectAccess, createAsyncType, EntityKeys } from '../../dependencies';

const createTypes = createAsyncType({
    modulePrefix: EntityKeys.TRANSACTIONS,
});

export default strictObjectAccess({
    ...createTypes({
        typePrefix: 'CREATE_TRANSACTION_',
    }),
    ...createTypes({
        typePrefix: 'FETCH_TRANSACTIONS_',
    }),
});
