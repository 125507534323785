export function canUseWebP() {
    const elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').includes('data:image/webp');
    }

    // very old browser like IE 8, canvas not supported
    return false;
}

export const WEBP_IS_SUPPORTED = canUseWebP();
