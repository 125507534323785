import { React } from '../../dependencies';

const SoundOn = props => (
    <svg viewBox="0 0 24 20" {...props}>
        <g>
            <path d="M22,16.5 L24,16.5 L24,4.5 L22,4.5 L22,16.5 Z M18,14.5 L20,14.5 L20,6.5 L18,6.5 L18,14.5 Z M2,12.5 L4,12.5 L4,8.5 L2,8.5 L2,12.5 Z M6,7.236 C8.921,5.775 11.164,4.654 14,3.236 L14,16.764 C11.165,15.346 8.921,14.225 6,12.764 L6,7.236 Z M0,6.5 L0,14.5 L3.667,14.5 L16,20 L16,0 L3.667,6.5 L0,6.5 Z" />
        </g>
    </svg>
);

export default SoundOn;
