import { React } from '../../dependencies';

const Download = props => (
    <svg viewBox="0 0 16 16" {...props}>
        <g>
            <polygon points="11.9997 7.9999 11.9997 9.9999 14.0007 9.9999 14.0007 13.9999 1.9997 13.9999 1.9997 9.9999 3.9997 9.9999 3.9997 7.9999 -0.0003 7.9999 -0.0003 8.9999 -0.0003 9.9999 -0.0003 13.9999 -0.0003 14.9999 -0.0003 15.9999 16.0007 15.9999 16.0007 7.9999" />
            <polygon points="7.0002 -0.0002 7.0002 6.4138 5.8792 5.2928 4.4652 6.7068 8.0002 10.2418 11.5352 6.7068 10.1212 5.2928 9.0002 6.4138 9.0002 -0.0002" />
        </g>
    </svg>
);

export default Download;
