import {
    sagaEffects,
    Log,
    Config,
    createUIErrorMessage,
    firebaseApp,
    Products,
    AuthSelectors,
    MessagesActions,
    ModalsActions,
} from '../../dependencies';
import { types, createTransactionFailure, createTransactionSuccess, createTransactionCancel } from '../actions';

const { put, takeEvery, select, delay, call, race, take, fork } = sagaEffects;

const { collections, customProductId } = Config;

function incrementProductPopularityCounter(productId) {
    return firebaseApp.database
        .ref(`${collections.favouriteProduct.replace(':productId', productId)}`)
        .transaction(productCounter => productCounter + 1);
}

function* composeTransaction(action) {
    const { productId } = action.meta;
    let product = yield select(Products.Selectors.productSelector, productId);

    if (!product) {
        product = action.payload;
    }

    if (!product) {
        throw new Error(`Product with '${productId}' ID does not exist.`);
    }

    const user = yield select(AuthSelectors.authUserSelector);

    return {
        email: user.email,
        description: product.name,
        productId,
        amount: product.price * -1,
        timestamp: Date.now(),
    };
}

function* transactionIsProcessing(transaction) {
    // NOTE: Give user some time to be able to cancel the transaction.
    const feedbackWindowDuration = 4 * 1000;

    yield put(
        MessagesActions.displayInfoMessage({
            message: {
                id:
                    transaction.productId === customProductId
                        ? 'transaction.success.custom'
                        : 'transaction.success.product',
                values: {
                    productName: transaction.description,
                },
            },
            button: {
                message: {
                    id: 'transaction.cancel',
                },
                onClick: createTransactionCancel(transaction.productId),
            },
            options: {
                duration: feedbackWindowDuration,
            },
        }),
    );

    yield delay(feedbackWindowDuration);
}

function* createTransaction(action) {
    const { productId } = action.meta;

    try {
        const transaction = yield composeTransaction(action);

        yield put(ModalsActions.closeModal(Config.modals.addTransaction));

        const result = yield race({
            task: call(transactionIsProcessing, transaction),
            cancel: take(
                cancelAction =>
                    cancelAction.type === types.CREATE_TRANSACTION_CANCEL && cancelAction.meta.productId === productId,
            ),
        });

        if (result && result.cancel) {
            yield put(
                MessagesActions.displayInfoMessage({
                    message: {
                        id: 'transaction.cancelled',
                        values: {
                            productName: transaction.description,
                        },
                    },
                }),
            );
            return;
        }

        const transactionSnap = yield firebaseApp.firestore.collection(collections.transactions).add(transaction);

        yield put(
            createTransactionSuccess(productId, {
                ...transaction,
                id: transactionSnap.id,
            }),
        );

        yield fork(incrementProductPopularityCounter, productId);
    } catch (e) {
        Log.error(e);

        const uiError = createUIErrorMessage(e);

        yield put(createTransactionFailure(productId, uiError));
    }
}

export default function*() {
    yield takeEvery(types.CREATE_TRANSACTION_REQUEST, createTransaction);
}
