import { combineReducers } from 'modules/entities/dependencies';
import { EntityKeys } from 'modules/entities/constants';

import { entityReducer as authEntityReducer } from 'modules/entities/modules/auth';
import { entityReducer as productsEntityReducer } from 'modules/entities/modules/products';
import { entityReducer as transactionsEntityReducer } from 'modules/entities/modules/transactions';
import { entityReducer as usersEntityReducer } from 'modules/entities/modules/users';

export default combineReducers({
    auth: authEntityReducer,
    [EntityKeys.PRODUCTS]: productsEntityReducer,
    [EntityKeys.TRANSACTIONS]: transactionsEntityReducer,
    balance: usersEntityReducer,
});
