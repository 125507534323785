import { React, PropTypes, FormattedMessage, childrenPropType } from '../../dependencies';

import Text from '../Text';
import { ChevronRightSmall as IconChevronRight } from '../Icons';

const ActionButton = ({ styles, Icon, message, children, props }) => (
    <button type="button" {...props}>
        <div className={styles.button}>
            <Icon className={styles.iconWrapper} />
            <Text>{message ? <FormattedMessage {...message} /> : children}</Text>
            <span className={styles.iconWrapper}>
                <IconChevronRight className={styles.iconChevronRight} />
            </span>
        </div>
    </button>
);

ActionButton.propTypes = {
    styles: PropTypes.shape().isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    children: childrenPropType,
    Icon: PropTypes.oneOfType([PropTypes.func]).isRequired,
    props: PropTypes.shape(),
};

ActionButton.defaultProps = {
    props: null,
    children: null,
    message: null,
};

export default ActionButton;
