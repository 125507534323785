import { React, PropTypes, UI, useSelector, useDispatch, Transactions, FormattedMessage } from '../../dependencies';

const { Button, ButtonSizes, ButtonTypes } = UI;
const { Actions, Selectors } = Transactions;

const CreateTransactionButton = ({ productId }) => {
    const { inProgress } = useSelector(state => Selectors.createTransactionApiSelector(state, productId));
    const dispatch = useDispatch();

    return (
        <Button
            size={ButtonSizes.SMALL}
            type={ButtonTypes.PRIMARY}
            onClick={() => dispatch(Actions.createTransactionRequest(productId))}
            style={{
                opacity: inProgress ? 0.75 : 1,
                cursor: inProgress ? 'progress' : 'pointer',
            }}
        >
            <FormattedMessage id="product.buy" />
        </Button>
    );
};

CreateTransactionButton.propTypes = {
    productId: PropTypes.string.isRequired,
};

export default React.memo(CreateTransactionButton);
