import { sagaEffects, Log, Config, createUIErrorMessage, firebaseApp, Utils, AuthSelectors } from '../../dependencies';
import { types, fetchTransactionsFailure, fetchTransactionsSuccess } from '../actions';

const { put, takeLeading, select } = sagaEffects;

const { collections } = Config;

function* fetchTransactions() {
    try {
        const user = yield select(AuthSelectors.authUserSelector);

        // TODO this should be in entities/modules/history
        const querySnap = yield firebaseApp.firestore
            .collection(collections.history)
            .where('email', '==', user.email)
            .orderBy('timestamp', 'desc')
            // TODO: implement pagination
            .limit(10)
            .get();

        const payload = Utils.composePayload(querySnap);

        yield put(fetchTransactionsSuccess(payload));
    } catch (e) {
        Log.error(e);

        const uiError = createUIErrorMessage(e);

        yield put(fetchTransactionsFailure(uiError));
    }
}

export default function*() {
    yield takeLeading(types.FETCH_TRANSACTIONS_REQUEST, fetchTransactions);
}
