export const body = () => ({
    margin: '2rem 0 1.5rem',
});

export const buttons = () => ({
    display: 'grid',
    marginLeft: 'auto',
    marginRight: 0,
    justifyContent: 'flex-end',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: '0.5rem',
});
