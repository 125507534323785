import PropTypes from 'prop-types';

export const date = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]);

export const id = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const value = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const message = PropTypes.shape({
    id: PropTypes.string.isRequired,
});
