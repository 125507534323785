import { sagaEffects, Log, Config, createUIErrorMessage, firebaseApp } from '../../dependencies';
import { types, fetchUserFailure, fetchUserSuccess } from '../actions';

const { put, takeEvery } = sagaEffects;

const { collections } = Config;

function* fetchUser(action) {
    const { userId } = action.meta;

    try {
        const userSnap = yield firebaseApp.firestore
            .collection(collections.users)
            .doc(userId)
            .get();

        yield put(
            fetchUserSuccess(userId, {
                balance: 0,
                ...userSnap.data(),
            }),
        );
    } catch (e) {
        Log.error(e);

        const uiError = createUIErrorMessage(e);

        yield put(fetchUserFailure(userId, uiError));
    }
}

export default function*() {
    yield takeEvery(types.FETCH_USER_REQUEST, fetchUser);
}
