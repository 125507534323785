import { sagaEffects, Log, Config, createUIErrorMessage, firebaseApp, Utils } from '../../dependencies';
import { types, fetchProductsFailure, fetchProductsSuccess } from '../actions';

const { put, takeLeading, all } = sagaEffects;

const { collections } = Config;

const PRODUCTS_VERSION = 'products-version';

const Storage = {
    get(key) {
        const item = localStorage.getItem(key);

        return item ? JSON.parse(item) : null;
    },
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    remove(key) {
        localStorage.removeItem(key);
    },
};

function* getLatestProductVersion() {
    const latestProductVersionSnap = yield firebaseApp.database.ref(PRODUCTS_VERSION).once('value');

    return latestProductVersionSnap.exists ? latestProductVersionSnap.val() : null;
}

function* getFavouriteProductsStats() {
    const snap = yield firebaseApp.database.ref(Config.collections.favouriteProducts).once('value');

    return snap.exists ? snap.val() || {} : {};
}

function sortProductsByPopularity(favouriteProductsStats, productIds) {
    return productIds.sort((productIdA, productIdB) => {
        const productAPriority = favouriteProductsStats[productIdA] || 0;
        const productBPriority = favouriteProductsStats[productIdB] || 0;

        return productBPriority - productAPriority;
    });
}

function* fetchProducts() {
    try {
        const [latestProductVersion, favouriteProductsStats] = yield all([
            getLatestProductVersion(),
            getFavouriteProductsStats(),
        ]);

        const cachedProductVersion = Storage.get(PRODUCTS_VERSION);

        if (latestProductVersion && cachedProductVersion && latestProductVersion === cachedProductVersion) {
            const byId = Storage.get(collections.products);
            const ids = Object.keys(byId);

            yield put(
                fetchProductsSuccess({
                    byId,
                    ids: sortProductsByPopularity(favouriteProductsStats, ids),
                }),
            );
            return;
            // eslint-disable-next-line
        } else {
            Storage.remove(PRODUCTS_VERSION);
            Storage.remove(collections.products);
        }

        const productsSnapshot = yield firebaseApp.firestore
            .collection(collections.products)
            .limit(1000)
            .get();

        const payload = Utils.composePayload(productsSnapshot);

        for (const id of payload.ids) {
            const item = payload.byId[id];

            payload.byId[id] = {
                ...item,
                normalizedFilter: Utils.normalizeInput(item.name + (item.priceDescription || '')),
            };
        }

        payload.ids = sortProductsByPopularity(favouriteProductsStats, payload.ids);

        yield put(fetchProductsSuccess(payload));

        if (latestProductVersion) {
            Storage.set(PRODUCTS_VERSION, latestProductVersion);
            Storage.set(collections.products, payload.byId);
        }
    } catch (e) {
        Log.error(e);

        const uiError = createUIErrorMessage(e);

        yield put(fetchProductsFailure(uiError));

        Storage.remove(PRODUCTS_VERSION);
        Storage.remove(collections.products);
    }
}

export default function*() {
    yield takeLeading(types.FETCH_PRODUCTS_REQUEST, fetchProducts);
}
