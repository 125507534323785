import types from './types';

export const createTransactionRequest = (productId, customProduct) => ({
    type: types.CREATE_TRANSACTION_REQUEST,
    meta: { productId },
    payload: customProduct,
});

export const createTransactionSuccess = (productId, payload) => ({
    type: types.CREATE_TRANSACTION_SUCCESS,
    meta: { productId },
    payload,
});

export const createTransactionFailure = (productId, error) => ({
    type: types.CREATE_TRANSACTION_FAILURE,
    meta: { productId },
    error,
});

export const createTransactionCancel = productId => ({
    type: types.CREATE_TRANSACTION_CANCEL,
    meta: { productId },
});

export const createTransactionReset = productId => ({
    type: types.CREATE_TRANSACTION_RESET,
    meta: { productId },
});
