import { React, FelaComponent, PropTypes, Icons } from '../../../dependencies';

import style from './ClearButton.style';

const ClearButton = ({ onClick }) => (
    <FelaComponent style={style}>
        {({ className }) => (
            <button type="button" onClick={onClick} className={className}>
                <Icons.Close />
            </button>
        )}
    </FelaComponent>
);

ClearButton.propTypes = {
    onClick: PropTypes.func,
};

ClearButton.defaultProps = {
    onClick: () => {},
};

export default ClearButton;
