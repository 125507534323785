import { React, theme } from '../../dependencies';

const { colors } = theme;

const Logo = props => (
    <svg viewBox="0 0 19 19" {...props}>
        <defs>
            <polygon points="0 0.10549 4.7546875 0.10549 4.7546875 4.9 0 4.9" />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon
                fill={colors.primaryBlue}
                points="18.8990087 0 13.1900738 0.147855454 13.1007917 1.54319487 16.368372 1.45329281 8 9.94940898 9.03552658 11 17.4031668 2.50388382 17.4924489 6.00114826 19 6.04647079"
            />
            <g transform="translate(0.000000, 2.000000)">
                <path
                    d="M13.8040708,0.54362 C11.9964042,0.08722 10.3849458,0.90552 9.30544583,2.30552 C9.15527917,2.50012 8.85352917,2.50502 8.70194583,2.30972 C7.5409875,0.81312 5.75882083,-0.01638 3.85907083,0.64792 C0.8514875,1.70142 -0.1982625,6.37252 3.9412375,10.28132 C8.32227917,14.41902 9.02352917,14.97902 13.9251958,10.41432 C18.3473208,6.29412 17.1282792,1.38362 13.8040708,0.54362"
                    fill={colors.red}
                />
                <polygon
                    fill={colors.primaryBlue}
                    points="9.84009583 7.8099 4.01972083 13.5611 3.0181375 12.5713 8.8385125 6.8201"
                />
                <polygon
                    fill={colors.primaryBlue}
                    points="6.77017917 9.91571 2.20072083 10.03891 2.11430417 11.35351 4.3207625 11.28491 5.29542917 11.27021 5.32234583 12.27471 5.40947083 14.66801 6.86934583 14.71071"
                />
                <g transform="translate(0.000000, 11.900000)">
                    <mask fill="white">
                        <use xlinkHref="#path-1" />
                    </mask>
                    <g />
                    <polygon
                        fill={colors.primaryBlue}
                        mask="url(#mask-2)"
                        points="4.65552083 0.10549 0.0860625 0.22799 -0.000354166667 1.54329 2.20610417 1.47469 3.18077083 1.45999 3.2076875 2.46449 3.2948125 4.85779 4.7546875 4.90049"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default Logo;
