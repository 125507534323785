import { reducer as ui } from 'modules/ui';
import { reducer as productsFilter } from 'modules/products';

import entities from './entities';
import api from './api';

// NOTE:
// Do not use combineReducers() here,
// export reducers as plain object, not as a function.
const reducers = {
    entities,
    api,
    ui,
    productsFilter,
};

export default reducers;
