export const ButtonTypes = {
    DEFAULT: 'default',
    PRIMARY: 'primary',
};

export const ButtonSizes = {
    NORMAL: 'normal',
    LARGE: 'large',
    SMALL: 'small',
};

export const AntdButtonSizes = {
    ...ButtonSizes,
    [ButtonSizes.NORMAL]: 'default',
};
